import React, { useState } from 'react'
import axios from 'axios'
import { PAYMENT_STEPS } from '../../lib/utils'

const AddFund = (props) => {
	const {advertiser_id, setCurrentStep, currentPaymentMethod} = { ...props }

	const [amount, setAmount] = useState(0)
	const [errorMessage, setErrorMessage] = useState('')
	const [showPrompt, setShowPrompt] = useState(false)
	const [saving, setSaving] = useState(false);
	const [saved, setSaved] = useState(false);

	const handleOnUseAnotherCard = () => {
		setCurrentStep(PAYMENT_STEPS.CHOOSE_PAYMENT_METHOD)
	}

	const handleOnChangeAmount = (value) => {
    let newValue = Number(value).toFixed(0);
    setAmount(newValue)
  }

  const handleOnAddFunds = () =>{
  	if (saving || saved) { return; }
  	setSaving(true);
  	setSaved(false);
    axios.post(`/advertisers/${advertiser_id}/stripe_charges.json`, {
        authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
        payment_method_id: currentPaymentMethod.id,
        amount: amount
    }).then((response)=>{
      setShowPrompt(true);
      setSaved(true);
      setTimeout(()=>{
        setSaved(false);
        setCurrentStep(PAYMENT_STEPS.CLOSED)
      }, 1500);
    }).catch((err)=>{
      console.log(err)
      setSaved(false);
      if (err.response.data.error) {
        setErrorMessage(err.response.data.error)
      } else {
        setErrorMessage(err.message)
      }
    }).finally(()=>{
      setSaving(false);
    });
  }

	return(
	  <div>
	    <h1 className="h3 center-align">Add funds</h1>
	    <div className="input-amount-row mt20 center-align">
	      <div className="input-amount-wp">
          <input id="add-fund-amount" className="input-amount-txt js-amount no-spinner" type="number" placeholder="0.00" value={amount} autoFocus onChange={(e) => handleOnChangeAmount(e.target.value)}/>
	      </div>
	      <div className="symbol">USD</div>
	    </div>
	    <hr className="divider mv30" />
	    <div>From</div>
	    <div className="card-box pd20 mt10 bg-light round-10">
	      <span className="txt dib vam">
	        <strong className="name">{currentPaymentMethod.brand}</strong>
	        <span className="info-items mt5">
	          <span className="note">{currentPaymentMethod.funding}</span>
	          {currentPaymentMethod.type === 'card' &&
	          	<span className="note last-digitals">{currentPaymentMethod.last4}</span>
	        	}
	        </span>
	      </span>
	    </div>
	    <div className="mt30 center-align">
	      <button className="btn lg fill" disabled={saving || saved} onClick={()=>{handleOnAddFunds()}}>{saving ? 'Processing...' : saved ? 'Saved' : 'Add Funds'}</button>
	      {showPrompt && amount !== 0 && <span className="fund-added" style={{display: 'inline-block', opacity: '1', animation: 'plus 2s'}}>+{amount}</span>
	      }
	    </div>
	    <div className="mt60 center-align">
	      {errorMessage != '' &&
          <div className="error  small" role="alert" aria-relevant="all">
            <div className="txt">{errorMessage}</div>
          </div>
        }
	      <div className="mt15 center-align">
	        <button className="btn lg outline" onClick={()=> handleOnUseAnotherCard()}>Use another card</button>
	      </div>
	    </div>
	    <div className="note constrain-xs block-center ib mt40">We’ll use your available balance when you make a purchase. <br /> If you don’t have enough funds in your account, we’ll ask you to add funds before you can make a purchase. <br /> Update your <a className="tdu" href={`/advertisers/${advertiser_id}/payment_methods`}>payment methods</a>.
      </div>
	  </div>
	)
}

export default AddFund