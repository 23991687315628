import React, { useState, useEffect } from 'react'
import AddPaymentMethodDialog from './AddPaymentMethodDialog'

const AddPaymentMethodButton = (props) => {
  const {advertiser_id} = {...props}

  const [dialog, setDialog] = useState(false)

  useEffect(()=>{
    if (dialog === false){
      // window.location.reload();
    }
  }, [dialog])

  return (
    <div>
      <button className ="btn lg fill full" onClick={() => setDialog(!dialog)}>Link a card/bank</button>
      <AddPaymentMethodDialog dialog={dialog} setDialog={setDialog} advertiser_id={advertiser_id} />
    </div>
  );
};

export default AddPaymentMethodButton; 