import React, { useState, useEffect } from 'react'
import { Modal, Dialog } from 'react-dialog-polyfill'
import axios from 'axios'

const RemovePaymentMethodButtonDialog = (props) => {
  const { advertiser_id, payment_method_type, payment_method_id, brand, last4 } = { ...props }

  const [dialog, setDialog] = useState(false)
  const authenticity_token = document.head.querySelector('meta[name="csrf-token"]').content

  return (
    <div> 
      {payment_method_type === "card" &&
        <button className="btn lg outline" onClick={()=>setDialog(true)}>Remove card</button>
      }
      {payment_method_type === "bank_account" &&
        <button className="btn lg outline" onClick={()=>setDialog(true)}>Remove account</button>
      }

      <Modal className="dialog fixed center-align" open={dialog}>
        <h1 className="h3">Are you sure?</h1>
        {payment_method_type === "card" &&
          <div className="note">You are about to remove card end with <strong data-name="">{last4}</strong></div>
        }
        {payment_method_type === "bank_account" &&
          <div className="note">You are about to remove bank account <strong data-name="">{brand}</strong></div>
        }
        <div className="mt40">
          <div className="ib">
            <form className="button_to" method="post" action={`/advertisers/${advertiser_id}/payment_methods/${payment_method_id}/remove_payment_method`}>
              <input type="hidden" name="_method" value="delete" autoComplete="off" />
              <input action="move_payment_method" className="btn lg danger" type="submit" value="Remove" />
              <input type="hidden" name="authenticity_token" value={authenticity_token} autoComplete="off" />
            </form>
          </div>
          <button className="btn lg text" data-dialog-close="" onClick={()=>setDialog(false)}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
};

export default RemovePaymentMethodButtonDialog; 