import React, { useState } from 'react'
import axios from 'axios'

const AddBankAccount = (props) => {
  const {advertiser_id} = { ...props }

  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [bankRoutingNumber, setBankRoutingNumber] = useState('')
  const [bankAccountHolderType, setBankAccountHolderType] = useState('company')
  const [bankAccountHolderName, setBankAccountHolderName] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const handleOnChangeBankAccountNumber = (value) => {
    let newValue = value.replace(/\D+/g, '');
    newValue = newValue.substring(0, 12)
    setBankAccountNumber(newValue)
  }

  const handleOnChangeBankRoutingNumber = (value) => {
    let newValue = value.replace(/\D+/g, '');
    newValue = newValue.substring(0, 9)
    setBankRoutingNumber(newValue)
  }

  const handleOnChooseOne = (value) => {
    setBankAccountHolderType(value)
  }

  const handleOnLinkBankAccount = (e) =>{
    e.preventDefault()
    axios.post(`/advertisers/${advertiser_id}/stripe_payment_methods.json`, {
        authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
        type: 'bank_account',
        bank_account_number: bankAccountNumber,
        bank_routing_number: bankRoutingNumber,
        bank_account_holder_type: bankAccountHolderType,
        bank_account_holder_name: bankAccountHolderName
    }).then((response)=>{
      window.location.href = response.data.verification_url;
    }).catch((err)=>{
      console.log(err)
      if (err.response.data.error) {
        setErrorMessage(err.response.data.error)
      } else {
        setErrorMessage(err.message)
      }
    });
  }

  return (
    <div>
      <h1 className="h3 center-align">Link A U.S. Bank Account</h1>
      <form className="form-stack mt30" method="post" aria-label="Bank log in field">
        <ul className="mt30 no-bullet">
          <li>
            <div className="fz14 mb10">Account type</div>
            <span className="radio">
              <input type="radio" name="bank_account_holder_type" id="type_company" value="company"
              onChange={(e)=> {handleOnChooseOne(e.target.value)}}
              checked={bankAccountHolderType === 'company'}
              />
              <label htmlFor="type_company"><span className="icon"></span> Company </label>
            </span>
            <span className="radio ml20">
	  	        <input type="radio" name="bank_account_holder_type" id="type_individual" value="individual"
		        	onChange={(e)=> {handleOnChooseOne(e.target.value)}}
		        	checked={bankAccountHolderType === 'individual'}
		        	/>
              <label htmlFor="type_individual"><span className="icon"></span> Individual </label>
            </span>
          </li>
          <li>
            <label htmlFor="bank-account-holder-name">Account Holder Name *</label>
            <input type="text" id="bank-account-holder-name" onChange={(e) => setBankAccountHolderName(e.target.value)} value={bankAccountHolderName}/>
          </li>
          <li>
            <label htmlFor="rounting-number">Routing Number *</label>
            <input type="text" id="rounting-number" onChange={(e) => handleOnChangeBankRoutingNumber(e.target.value)} value={bankRoutingNumber}/>
          </li>
          <li>
            <label htmlFor="account-number">Account Number *</label>
            <input type="text" id="account-number" onChange={(e) => handleOnChangeBankAccountNumber(e.target.value)} value={bankAccountNumber}/>
          </li>
        </ul>
        <div className="center-align mt20 note">Be sure to double-check your account number. Banks may not flag errors until you transfer money.</div>
        {errorMessage != '' &&
          <div className="error  small" role="alert" aria-relevant="all">
            <div className="txt">{errorMessage}</div>
          </div>
        }
        <div className="center-align mt20">
          <button className="btn lg fill" onClick={(e)=> handleOnLinkBankAccount(e)}>Agree and Link</button>
        </div>
      </form>
    </div>    
  );
};


export default AddBankAccount;
