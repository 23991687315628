import React, { useState, useEffect } from 'react'
import { Modal, Dialog } from 'react-dialog-polyfill'
import AddCard from './AddCard'
import { UPDATE_CARD_STEPS } from '../../lib/utils'


const UpdateCardDialog = (props) => {
  const { dialog, setDialog, advertiser_id, payment_method} = { ...props }

  const [currentStep, setCurrentStep] = useState(UPDATE_CARD_STEPS.INIT)

  useEffect(()=>{
    if (dialog === true){
      setCurrentStep(UPDATE_CARD_STEPS.UPDATE_CARD)
    }
  }, [dialog])

  useEffect(()=>{
    if (currentStep === UPDATE_CARD_STEPS.CLOSED){
      setDialog(false)
      window.location.reload();
    }
  }, [currentStep])

  const handleOnClose = () => {
    setCurrentStep(UPDATE_CARD_STEPS.CLOSED)
    setDialog(false)
    window.location.reload();
  }

  const handleOnBack = () => {
    setCurrentStep(currentStep - 1)
  }

  return (
    <div> 
      <Modal className='dialog fixed constrain-sm' open={dialog}>
        <div className="dialog-tools justify-row">
          { currentStep > 1 && 
            <button className="dialog-tool" type="button" aria-label="Back" onClick={() => handleOnBack()}>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 11.5L.942 5.942 6.384.5" stroke="currentColor"></path>
              </svg>
            </button>
          }
          &nbsp;
          <button className="dialog-tool" type="button" aria-label="Close" data-dialog-close="" onClick={() => handleOnClose() }>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13.866l6.433-6.433M13.866 1L7.433 7.433m0 0L1.134 1.134m6.299 6.299L14 14" stroke="currentColor"></path>
            </svg>
          </button>
        </div>
        {currentStep === UPDATE_CARD_STEPS.UPDATE_CARD &&
          <AddCard advertiser_id={advertiser_id} setCurrentStep={setCurrentStep} nextStep={UPDATE_CARD_STEPS.CLOSED} editCard={true} payment_method={payment_method}/>
        }
      </Modal>
    </div>
  );
};

export default UpdateCardDialog; 