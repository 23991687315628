import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageIconWarning from '../../../images/icon/icon-warning.svg'

const ErrorBox = (props) => {
  return (
    <div className="error icon-warning big" role="alert" aria-relevant="all">
      <img className="icon" src={ImageIconWarning} width="39" height="34" alt="" />
      <div className="txt">{props.message}</div>
    </div>
  );
};

ErrorBox.propTypes = {
  message: PropTypes.string,
};

export default ErrorBox;
