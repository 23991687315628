import React, { useState, useEffect } from 'react'
import { Modal, Dialog } from 'react-dialog-polyfill'
import AddCard from './AddCard'
import AddBankAccount from './AddBankAccount'
import { ADD_PAYMENT_METHOD_STEPS } from '../../lib/utils'
import ImageThumbCard from '../../../images/thumb-card.svg'
import ImageThumbBank from '../../../images/thumb-bank.svg'


const AddPaymentMethodDialog = (props) => {
  const { dialog, setDialog, advertiser_id} = { ...props }
  const payment_method = {
    id: '',
    cardholder_name: '',
    exp_year: '',
    exp_month: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    }
  }

  const [currentStep, setCurrentStep] = useState(ADD_PAYMENT_METHOD_STEPS.INIT)
  const [type, setType] = useState('card')

  useEffect(()=>{
    if (dialog === true){
      setCurrentStep(ADD_PAYMENT_METHOD_STEPS.CHOOSE_TYPE)
    }
  }, [dialog])

  useEffect(()=>{
    if (currentStep === ADD_PAYMENT_METHOD_STEPS.CLOSED){
      setDialog(false)
      window.location.reload();
    }
  }, [currentStep])

  const handleOnClose = () => {
    setCurrentStep(ADD_PAYMENT_METHOD_STEPS.CLOSED)
    setDialog(false)
    window.location.reload();
  }

  const handleOnBack = () => {
      setCurrentStep(currentStep - 1)      
  }

  const handleOnChooseOne = (value) => {
    setType(value)
  }

  const handleOnContinue = () => {
    setCurrentStep(ADD_PAYMENT_METHOD_STEPS.ADD_PAYMENT_METHOD)
  }

  return (
    <div> 
      <Modal className='dialog fixed constrain-sm' open={dialog}>
        <div className="dialog-tools justify-row">
          { currentStep > 1 && 
            <button className="dialog-tool" type="button" aria-label="Back" onClick={() => handleOnBack()}>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 11.5L.942 5.942 6.384.5" stroke="currentColor"></path>
              </svg>
            </button>
          }
          &nbsp;
          <button className="dialog-tool" type="button" aria-label="Close" data-dialog-close="" onClick={() => handleOnClose() }>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13.866l6.433-6.433M13.866 1L7.433 7.433m0 0L1.134 1.134m6.299 6.299L14 14" stroke="currentColor"></path>
            </svg>
          </button>
        </div>
        {currentStep === ADD_PAYMENT_METHOD_STEPS.CHOOSE_TYPE &&
          <div>
            <ul className="no-bullet">
              <li>
                <div className="radio">
                  <input type="radio" name="payment-method-type" id="payment-method-card" value="card" 
                  onChange={(e)=> {handleOnChooseOne(e.target.value)}} 
                  checked={type === 'card'}
                  />
                  <label className="card-box round-10 pd20" htmlFor="payment-method-card">
                    <span className="icon"></span>
                    <img className="dib vam" src={ImageThumbCard} alt="" />
                    <span className="txt dib vam">
                      <strong className="name">Link a debit or credit card</strong>
                      <span className="note mt5"></span>
                    </span>
                  </label>
                </div>
                <hr className="divider" />
              </li>
              <li>
                <div className="radio">
                  <input type="radio" name="payment-method-type" id="payment-method-bank-account" value="bank_account" 
                  onChange={(e)=> {handleOnChooseOne(e.target.value)}} 
                  checked={type === 'bank_account'}
                  />
                  <label className="card-box round-10 pd20" htmlFor="payment-method-bank-account">
                    <span className="icon"></span>
                    <img className="dib vam" src={ImageThumbBank} alt="" />
                    <span className="txt dib vam">
                      <strong className="name">Link a bank account</strong>
                      <span className="note mt5"></span>
                    </span>
                  </label>
                </div>
              </li> 
            </ul>
            <div className="center-align mt30">
              <button className="btn lg fill" onClick={()=>handleOnContinue()}>Continue</button>
            </div>            
          </div>
        }
        {currentStep === ADD_PAYMENT_METHOD_STEPS.ADD_PAYMENT_METHOD && type === 'card' &&
          <AddCard advertiser_id={advertiser_id} setCurrentStep={setCurrentStep} nextStep={ADD_PAYMENT_METHOD_STEPS.CLOSED} editCard={false} payment_method={payment_method}/>
        }
        {currentStep === ADD_PAYMENT_METHOD_STEPS.ADD_PAYMENT_METHOD && type === 'bank_account' &&
          <AddBankAccount advertiser_id={advertiser_id}/>
        }
      </Modal>
    </div>
  );
};

export default AddPaymentMethodDialog; 
