import React, { useState } from 'react'
import { Modal } from 'react-dialog-polyfill'

const DeactivateUserButtonDialog = (props) => {
  const { confirm_path } = { ...props }

  const [dialog, setDialog] = useState(false)

  return <div>
    <a href="javascript:void(0);" className="btn sm white ml10" onClick={() => setDialog(true)}>Deactivate</a>
    <Modal className="dialog fixed center-align" open={dialog}>
      <h1 className="h3">Are you sure?</h1>
      <div className="note">You are about to deactive <strong>yourself</strong>. You will be logged out<br/>and will <strong>not be able to sign in</strong> again.</div>
      <div className="mt40">
        <a className="btn lg danger" data-method="patch" style={{ display: 'inline-block' }} href={confirm_path}>Deactivate</a>
        <button className="btn lg text" style={{ display: 'inline-block' }} onClick={()=>setDialog(false)}>Cancel</button>
      </div>
    </Modal>
  </div>;
};

export default DeactivateUserButtonDialog; 
