import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import ContentEditable from 'react-contenteditable'
import slugify from 'voca/slugify';
import he from 'he';
import debounce from 'lodash.debounce';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ErrorBox from './ErrorBox';
import PreApprovedBox from './PreApprovedBox';
import CampaignInlineSubmissionConfirmedDialog from './CampaignInlineSubmissionConfirmedDialog'
import { toCurrency } from '../../lib/utils';
import ImageCheckedGreen from '../../../images/icon/checked-green.svg'
import ImageDownAngle from '../../../images/icon/down-angle.svg'
import ImageClose from '../../../images/icon/icon-close.svg'
import ImageExchange from '../../../images/icon/icon-exchange.svg'
import ImageMp3 from '../../../images/icon/icon-mp3.svg'
import ImageFile from '../../../images/icon/icon-file.svg'
import AddFundDialog from './AddFundDialog'

const STEPS = {
  SELECT_TYPE: 1,
  SCHEDULE: 2,
  TARGETING: 3,
  BUDGET: 4,
  PAYMENT: 5,
  CREATIVE: 6,
};
Object.freeze(STEPS);

const STEP_NAV_ITEMS = [
  { step: STEPS.SELECT_TYPE, title: 'Campaign Setup' },
  { step: STEPS.SCHEDULE, title: 'Schedule' },
  { step: STEPS.TARGETING, title: 'Targeting' },
  { step: STEPS.BUDGET, title: 'Budget' },
  { step: STEPS.PAYMENT, title: 'Payment' },
  { step: STEPS.CREATIVE, title: 'Creative' },
];
Object.freeze(STEP_NAV_ITEMS);

const STEP_VALIDATORS = {
  [STEPS.SELECT_TYPE]: (campaign, errors) => campaign.name && campaign.type && !errors?.name && !errors?.type,
  [STEPS.SCHEDULE]: (campaign, errors) => campaign.scheduled_start_date && campaign.scheduled_end_date && !errors?.scheduled_start_date && !errors?.scheduled_end_date,
  [STEPS.TARGETING]: (campaign, errors) => !errors?.section_targeting && !errors?.geo_targeting && !errors?.device_targeting,
  [STEPS.BUDGET]: (campaign, errors) => campaign.budget && !errors?.budget,
  [STEPS.PAYMENT]: (campaign, errors) => statusPaymentDone(campaign.status) && !errors?.payment,
  [STEPS.CREATIVE]: (campaign, errors) => false,
};
Object.freeze(STEP_VALIDATORS);

const statusPaymentDone = (status) => {
  if (!status) { return false; }
  if (status === 'draft') { return false; }
  return true;
};

const canGoToNextStep = (currentStep, campaign, errors) => {
  if (!STEP_VALIDATORS[currentStep]) { return false; }
  return STEP_VALIDATORS[currentStep](campaign, errors || {});
};

const CampaignInlineEditForm = (props) => {
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_TYPE);
  const [campaign, setCampaign] = useState({ id: props.campaign_id });
  const [activeCampaignTypeCategory, setActiveCampaignTypeCategory] = useState(null);
  const [geoTargets, setGeoTargets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shouldSave, setShouldSave] = useState(false);
  const [shouldSaveCreatives, setShouldSaveCreatives] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [geoTargetLookup, setGeoTargetLookup] = useState({});
  const [geoTargetsLoading, setGeoTargetsLoading] = useState({});
  const [geoTargetsExpanded, setGeoTargetsExpanded] = useState({});
  const [geoTargetSearchInput, setGeoTargetSearchInput] = useState(null);
  const [geoTargetShowCountryCode, setGeoTargetShowCountryCode] = useState(false);
  const [budgetAmount, setBudgetAmount] = useState(null);
  const [availFunds, setAvailFunds] = useState(null);
  const [availCredit, setAvailCredit] = useState(null);
  const [creditLimit, setCreditLimit] = useState(null);
  const [balancesLoading, setBalancesLoading] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [unknownCreativeErrors, setUnknownCreativeErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [activeCreativeTab, setActiveCreativeTab] = useState({});
  const [creativeFileUploading, setCreativeFileUploading] = useState({});
  const [lineItemErrors, setLineItemErrors] = useState({});
  const [creativeErrors, setCreativeErrors] = useState({});
  const [creativeDeletionErrors, setCreativeDeletionErrors] = useState({});
  const [shouldShowCreativeHasErrors, setShouldShowCreativeHasErrors] = useState(false);
  const [campaignReviewSubmissionError, setCampaignReviewSubmissionError] = useState(false);
  const [showCampaignInlineSubmissionConfirmedDialog, setShowCampaignInlineSubmissionConfirmedDialog] = useState(false);
  const [fundDialog, setFundDialog] = useState(false);
  const [secondaryHeight, setSecondaryHeight] = useState(0);
  const elementRef = useRef(null);

  // ====== Computed Start ======
  const singleProducts = props.campaign_types.filter(campaign_type => campaign_type.display_campaign_type_category === 'Single Product');
  const pakages = props.campaign_types.filter(campaign_type => campaign_type.display_campaign_type_category === 'Package');
  const currentCampaignTypeMeta = campaign.type ? props.campaign_types.find(campaign_type => campaign_type.class_name === campaign.type) : null;

  const campaignAttributesLocked = campaign.status && (campaign.status !== 'draft');
  const campaignStartAndEndDateLocked = campaign.status && !(['draft', 'paid', 'pending_approval', 'approved', 'ready'].includes(campaign.status));
  // const campaignTargetingBudgetPaymentLocked = campaign.status && !(['draft', 'paid', 'pending_approval'].includes(campaign.status));

  let scheduledDuration = '';
  if (campaign.scheduled_start_date && campaign.scheduled_end_date) {
    scheduledDuration = moment(campaign.scheduled_end_date).diff(moment(campaign.scheduled_start_date), 'days') + 1;
  }

  let minimumCampaignScheduledStartDate = moment().format('YYYY-MM-DD'); // default min is today
  if (campaign.scheduled_start_date_constraints) {
    minimumCampaignScheduledStartDate = campaign.scheduled_start_date_constraints['>='] || minimumCampaignScheduledStartDate;
  }

  let minimumCampaignScheduledEndDate = moment(); // default min is today
  let campaignScheduledEndDateIsImmutable = false;
  let calculateScheduledEndDateByStartDateFn = null;
  let scheduleType = 'Flight Dates';
  if (campaign.scheduled_end_date_constraints) {
    if (campaign.scheduled_end_date_constraints['>= start + n days']) {
      minimumCampaignScheduledEndDate = moment(campaign.scheduled_start_date || minimumCampaignScheduledStartDate).add(campaign.scheduled_end_date_constraints['>= start + n days'], 'days')
    }
    if (campaign.scheduled_end_date_constraints['= start + n days']) {
      let addDays = campaign.scheduled_end_date_constraints['= start + n days'];
      campaignScheduledEndDateIsImmutable = true;
      if (addDays === 6) { scheduleType = 'Flight Week' }
      else if (addDays === 29) { scheduleType = 'Flight Month' }
      calculateScheduledEndDateByStartDateFn = (startDate) => moment(startDate).add(addDays, 'days').format('YYYY-MM-DD');
    }
  }
  minimumCampaignScheduledEndDate = minimumCampaignScheduledEndDate.format('YYYY-MM-DD');

  const campaignHasErrors = Object.values(errors).some(errors => !!errors);
  const lineItemsHasErrors = Object.values(lineItemErrors).some(errors => !!errors);
  const creativeHasErrors = Object.values(creativeErrors).some(errors => !!errors);
  const canSubmit = !saving && !campaignHasErrors && !lineItemsHasErrors && !creativeHasErrors
  // ====== Computed End ======

  // call campaign api and initialize state
  useEffect(() => {
    if (props.campaign_id) {
      axios.get(`/advertisers/${props.advertiser_id}/campaigns/${campaign.id}.json`).then((response) => {
        let errors = response.data.errors;
        if (errors) {
          setErrors(errors);
          delete response.data.errors;
        }
        initCampaign(response.data);
        setActiveCampaignTypeCategory(props.campaign_types.find(campaign_type => campaign_type.class_name === response.data.type).display_campaign_type_category);
        if (props.force_init_step) { return; }
        setCurrentStepByCampaign(response.data, errors);
      }).catch((error) => {
        console.log(error);
        setUnknownError(true);
      }).finally(() => {
        setLoading(false);
        // Setting the height for creative header
        setSecondaryHeight(elementRef.current.clientHeight+64);
      });
    } else {
      setLoading(false);
    }
  }, []);

  // Auto save campaign with debounce
  useEffect(() => {
    if (!shouldSave) { return; }
    if (!campaign || !campaign.id) { return; }
    setShouldSave(false);
    debouncedSaveCampaign({updatedCampaign: campaign});
  }, [shouldSave, campaign]);

  // Auto save creative with debounce
  useEffect(() => {
    if (shouldSaveCreatives.length <= 0) { return; }
    if (!campaign || !campaign.id) { return; }
    debouncedSaveCreatives({updatedCampaign: campaign, updatedShouldSaveCreatives: shouldSaveCreatives})
  }, [shouldSaveCreatives, campaign]);

  // Initialize geo targets
  useEffect(() => {
    if (geoTargets) { return; }
    if (!campaign || !('geo_targeting' in campaign)) { return; }
    if (currentStep !== STEPS.TARGETING) { return; }

    loadRootGeoTargets();
  }, [currentStep, campaign]);

  // Load geo target search result
  useEffect(() => {
    if (geoTargetSearchInput === null) { return; }
    debouncedLoadSearchGeoTargets(geoTargetSearchInput);
  }, [geoTargetSearchInput]);

  // Initialize amount based on budget
  useEffect(() => {
    //if (currentStep !== STEPS.BUDGET) { return; }
    if (!campaign || !campaign.budget || !campaign.budget_customizable) { return; }

    loadBudgetAmountByCost(campaign.budget, campaign.id);
  }, [currentStep]);

  // Initialize balances
  useEffect(() => {
    //if (currentStep !== STEPS.PAYMENT) { return; }

    loadBalances();
  }, [currentStep]);

  // After add fund
  useEffect(()=>{
    if (fundDialog === false){
      loadBalances();
    }
  }, [fundDialog])

  // init / reset campaign data
  const initCampaign = (campaignData) => {
    let newUuids = [];
    campaignData.line_items = campaignData.line_items || [];
    for (const lineItem of campaignData.line_items) {
      lineItem.creatives = lineItem.creatives || [];
      if (lineItem.creatives.length <= 0) {
        let uuid = uuidv4();
        lineItem.creatives.push({ uuid: uuid });
        newUuids.push(uuid);
      }
    }
    setCampaign(campaignData);
    if (newUuids.length >= 0) {
      saveCreatives({updatedCampaign: campaignData, updatedShouldSaveCreatives: newUuids})
    }
    const _lineItemErrors = campaignData.line_items.reduce((obj, lineItem) => ({ ...obj, [lineItem.id]: lineItem.errors || null }), {});
    setLineItemErrors(_lineItemErrors);
    const _creativeErrors = campaignData.line_items.reduce((obj, lineItem) => ({ ...obj, ...(lineItem.creatives.reduce((_obj, creative) => ({ ..._obj, [creative.id]: creative.errors || null }), {})) }), {});
    setCreativeErrors(_creativeErrors);
  };

  // update campaign state, and save via api unless a new record
  const updateCampaign = (params) => {
    setCampaign(previousState => {
      return { ...previousState, ...params };
    });
    if (!campaign.id) { return; }
    setShouldSave(true);
  };

  const saveCampaign = ({updatedCampaign, rejectOnError}={}) => {
    if (saving) { return; }
    setSaving(true);

    const _campaign = updatedCampaign || campaign;
    let method, endpoint;
    if (_campaign.id) { // existing record
      method = axios.patch;
      endpoint = `/advertisers/${props.advertiser_id}/campaigns/${_campaign.id}.json`;
    } else { // new record
      method = axios.post;
      endpoint = `/advertisers/${props.advertiser_id}/campaigns.json`;
    }
    setUnknownError(false);
    setErrors({});
    return method(endpoint, {
      authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
      campaign: _campaign,
    }).then((response) => {
      if (!campaign.id) {
        initCampaign(response.data);
        history.replaceState(null, '', `/advertisers/${props.advertiser_id}/campaigns/${response.data.id}/edit`)
      }
      // Removing all references to nextStep to saving campaign
      // if (nextStep && canGoToNextStep(currentStep, _campaign)) { setCurrentStep(nextStep); }
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data || {});
        if (!_campaign.id) { // never skip to next step when failed if creating a new campaign
          return rejectOnError ? Promise.reject(error) : null;
        }
        // if (canGoToNextStep(currentStep, _campaign, error.response.data) && nextStep) { setCurrentStep(nextStep); }
        // return rejectOnError ? Promise.reject(error) : null;
      }
      setUnknownError(true);
      return rejectOnError ? Promise.reject(error) : null;
    }).finally(() => {
      setSaving(false);
    });
  };

  const debouncedSaveCampaign = useMemo(
    () => debounce(saveCampaign, 500)
  , []);

  const saveAndPayCampaign = () => {
    saveCampaign({ rejectOnError: true }).then(() => {
      setSaving(true);
      return axios.post(`/advertisers/${props.advertiser_id}/campaigns/${campaign.id}/pay.json`, {
        authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
      });
    }).then((response) => {
      let _campaign = response.data;
      initCampaign(_campaign);
      if (canGoToNextStep(currentStep, _campaign)) {
        setCurrentStep(STEPS.CREATIVE);
      } else {
        setUnknownError(true);
      }
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data || {});
        return;
      }
      setUnknownError(true);
    }).finally(() => {
      setSaving(false);
    });
  };

  const saveAndSubmitCampaign = () => {
    if (!canSubmit) { return; }

    setSaving(true);
    setSaved(false);
    setShouldShowCreativeHasErrors(false);
    setCampaignReviewSubmissionError(false);
    axios.post(`/advertisers/${props.advertiser_id}/campaigns/${campaign.id}/inline_submission.json`, {
      authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
    }).then((response) => {
      submissionConfirmed();
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 422) {
        setCampaignReviewSubmissionError(true);
        return;
      }
      setUnknownError(true);
    }).finally(() => {
      setSaving(false);
      setSaved(true);
      setShouldShowCreativeHasErrors(true);
      window.setTimeout(() => {
        setSaved(false);
      }, 2000);
    });
  };

  const submissionConfirmed = () => {
    setShowCampaignInlineSubmissionConfirmedDialog(true);
  };

  const initCreative = ({uuid, ...creativeData}={}) => {
    return setCampaign(previousState => {
      let newState = { ...previousState };
      for (const lineItem of newState.line_items || []) {
        for (const creative of lineItem.creatives || []) {
          if (creative.uuid !== uuid) { continue; }
          // found the matched creative
          Object.keys(creative).forEach(key => delete creative[key]);
          Object.assign(creative, creativeData);
          return newState;
        }
      }
      return previousState;
    });
  };

  const newCreative = (lineItem) => {
    setCampaign(previousState => {
      let newState = { ...previousState };
      for (const _lineItem of newState.line_items || []) {
        if (_lineItem.id !== lineItem.id) { continue; }
        _lineItem.creatives.push({ uuid: uuidv4(), name: `${lineItem.display_line_item_type} Creative ${_lineItem.creatives.length+1}` });
        return newState;
      }
      return previousState;
    });
  };

  const updateCreative = ({idOrUuid, ...params}={}) => {
    setCampaign(previousState => {
      let newState = { ...previousState };
      for (const lineItem of newState.line_items || []) {
        for (const creative of lineItem.creatives || []) {
          if (creative.id !== idOrUuid && creative.uuid !== idOrUuid) { continue; }
          // found the matched creative
          Object.assign(creative, params)
          return newState;
        }
      }
      return previousState;
    });
    if (!campaign.id) { return; }
    setShouldSaveCreatives(prevState => prevState.includes(idOrUuid) ? prevState : [ ...prevState, idOrUuid ]);
  };

  const putCreativeRemote = (creative, lineItem, _campaign) => {
    let method, endpoint;
    if (creative.id) { // existing record
      method = axios.patch;
      endpoint = `/advertisers/${props.advertiser_id}/campaigns/${_campaign.id}/line_items/${lineItem.id}/creatives/${creative.id}.json`;
    } else { // new record
      method = axios.post;
      endpoint = `/advertisers/${props.advertiser_id}/campaigns/${_campaign.id}/line_items/${lineItem.id}/creatives.json`;
    }
    return method(endpoint, {
      authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
      creative: creative,
    }).then((response) => {
      let _lineItem, _errors, creativeData, lineItemData, campaignData;
      (() => {
        const { line_item, errors, ..._creativeData } = response.data || {};
        _lineItem = line_item;
        _errors = errors;
        creativeData = _creativeData;
        campaignData = _creativeData.campaign;
      })()
      const _creativeErrors = _errors || null;
      (() => {
        const { errors, ..._lineItemData } = _lineItem || {};
        _errors = errors;
        lineItemData = _lineItemData
      })()
      const _lineItemErrors = _errors || null;
      if (!creative.id) { initCreative({ ...creativeData, uuid: creative.uuid }); }
      setCampaign(previousState => {
        let newState = { ...previousState };
        for (const _lineItem of newState.line_items || []) {
          if (_lineItem.id !== lineItemData.id) { continue; }
          // found matched line item
          Object.assign(_lineItem, lineItemData);
          newState.preapproved = campaignData.preapproved;
          return newState;
        }
        return previousState;
      });
      if (_creativeErrors) {
        setCreativeErrors(prevState => ({ ...prevState, [creative.id]: _creativeErrors }));
      }
      if (_lineItemErrors) {
        setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: _lineItemErrors }));
      }
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 422) {
        const { line_item, ..._creativeErrors } = error.response.data || {};
        const _lineItemErrors = (line_item || {}).errors || null;
        setCreativeErrors(prevState => ({ ...prevState, [creative.id || creative.uuid]: _creativeErrors }));
        setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: _lineItemErrors }));
        return;
      }
      setUnknownCreativeErrors(prevState => ({ ...prevState, [creative.id || creative.uuid]: true }));
    })
  };

  const saveCreatives = ({updatedCampaign, updatedShouldSaveCreatives}={}) => {
    setShouldSaveCreatives([]);
    setShouldShowCreativeHasErrors(false);
    if (!updatedShouldSaveCreatives || updatedShouldSaveCreatives.length <= 0) { return; }

    const _campaign = updatedCampaign || campaign;
    let impactedLineItemErrors = {};
    let impactedCreativeErrors = {};
    for (const idOrUuid of updatedShouldSaveCreatives) {
      ((idOrUuid) => {
        for (const lineItem of updatedCampaign.line_items || []) {
          for (const creative of lineItem.creatives || []) {
            if (creative.id !== idOrUuid && creative.uuid !== idOrUuid) { continue; }
            // found the matched creative
            impactedCreativeErrors[idOrUuid] = null;
            impactedLineItemErrors[lineItem.id] = null;
            return;
          }
        }
      })(idOrUuid);
    }
    setUnknownCreativeErrors(prevState => ({ ...prevState, ...impactedCreativeErrors }));
    setLineItemErrors(prevState => ({ ...prevState, ...impactedLineItemErrors }));
    setCreativeErrors(prevState => ({ ...prevState, ...impactedCreativeErrors }));

    for (const idOrUuid of updatedShouldSaveCreatives) {
      ((idOrUuid) => {
        for (const lineItem of updatedCampaign.line_items || []) {
          for (const creative of lineItem.creatives || []) {
            if (creative.id !== idOrUuid && creative.uuid !== idOrUuid) { continue; }
            // found the matched creative
            return putCreativeRemote(creative, lineItem, _campaign);
          }
        }
      })(idOrUuid);
    }
  };

  const debouncedSaveCreatives = useMemo(
    () => debounce(saveCreatives, 500)
  , []);

  const deleteCreative = (campaign, lineItem, creative) => {
    if (!creative.id) { // creative not save in backend yet
      // simply remove it from frontend
      setCampaign(previousState => {
        let newState = { ...previousState };
        for (const lineItem of newState.line_items || []) {
          if (!lineItem.creatives) { continue; }
          lineItem.creatives = lineItem.creatives.filter(_creative => _creative.id || _creative.uuid !== creative.uuid)
        }
        return newState;
      });
      return;
    }
    // creative also in backend
    setUnknownCreativeErrors(prevState => ({ ...prevState, [creative.id]: null }));
    setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: null }));
    setCreativeErrors(prevState => ({ ...prevState, [creative.id]: null }));
    setCreativeDeletionErrors(prevState => ({ ...prevState, [creative.id]: false }));
    let endpoint = `/advertisers/${props.advertiser_id}/campaigns/${campaign.id}/line_items/${lineItem.id}/creatives/${creative.id}.json`;
    return axios.delete(endpoint, {
      data: { authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content },
    }).then((response) => {
      const { line_item } = response.data || {};
      const { errors, is_valid } = line_item || {};
      const _lineItemErrors = errors || null;

      setCampaign(previousState => {
        let newState = { ...previousState };
        for (const _lineItem of newState.line_items || []) {
          if (_lineItem.id !== lineItem.id) { continue; }
          // match found
          Object.assign(_lineItem, { is_valid: is_valid || false });

          if (!_lineItem.creatives) { return newState; }
          _lineItem.creatives = _lineItem.creatives.filter(_creative => _creative.id !== creative.id)
          return newState;
        }
        return newState;
      });
      if (_lineItemErrors) {
        setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: _lineItemErrors }));
      }
    }).catch((error) => {
      console.log(error);
      setCreativeDeletionErrors(prevState => ({ ...prevState, [creative.id]: true }));
    });
  };

  // This actually does nothing since we already have auto save. This is only for UI and prevent to be saving creatives
  const checkCreatives = () => {
    if (saving || saved) { return; }
    setSaving(true);
    setSaved(false);
    setShouldShowCreativeHasErrors(false);
    window.setTimeout(() => {
      setShouldShowCreativeHasErrors(true);
      setSaving(false);
      setSaved(true);
      window.setTimeout(() => {
        setSaved(false);
      }, 2000);
    }, 500);
  };

  const setCurrentStepByCampaign = (campaign, errors) => {
    if (!campaign.id) {
      return setCurrentStep(STEPS.SELECT_TYPE);
    }
    for (const step in STEPS) {
      if (!STEP_VALIDATORS[STEPS[step]](campaign, errors || {})) {
        return setCurrentStep(STEPS[step]);
      }
    }
    setCurrentStep(STEPS.SELECT_TYPE);
  };

  // geo targets
  const loadGeoTargets = (promiseTargets, showCountryCode) => {
    setGeoTargetsLoading(previousState => {
      return { ...previousState, ...{ roots: true } };
    });
    setUnknownError(false);
    const promiseSelectedTargets = campaign.geo_targeting ? axios.get('/geo_targets.json', { params: { ids: campaign.geo_targeting.join(',') }}) : Promise.resolve(null);
    Promise.all([promiseTargets, promiseSelectedTargets]).then((responses) => {
      const responseRoot = responses[0];
      const responseSelected = responses[1];
      setGeoTargetsExpanded({});
      setGeoTargets(responseRoot.data);
      setGeoTargetShowCountryCode(showCountryCode);
      if (responseSelected) {
        const lookup = responseSelected.data.reduce((result, item) => {
          result[item.id] = (({ name, country_code, type, ancestor_ids }) => ({ name, country_code, type, ancestor_ids }))(item);
          return result;
        }, {})
        setGeoTargetLookup(previousState => {
          return { ...previousState, ...lookup };
        });
      }
    }).then(() => {
      return axios
    }).catch((error) => {
      console.log(error);
      setUnknownError(true);
    }).finally(() => {
      setGeoTargetsLoading(previousState => {
        return { ...previousState, ...{ roots: false } };
      });
    });
  };

  const loadRootGeoTargets = () => {
    loadGeoTargets(axios.get('/geo_targets.json'), false);
  };

  const loadGeoTarget = (target) => {
    if (target.children) { return; }

    setGeoTargetsLoading(previousState => {
      return { ...previousState, ...{ [target.id]: true } };
    });
    setUnknownError(false);
    axios.get(`/geo_targets/${target.id}.json`).then((response) => {
      setGeoTargets(previousState => {
        const findTarget = (targets) => {
          if (!targets) { return null; }
          let matchedTarget = targets.find(_t => _t.id === target.id)
          if (matchedTarget) { return matchedTarget; }
          for (let i = 0; i < targets.length; i++) {
            if (!targets[i].children) { continue; }
            matchedTarget = findTarget(targets[i].children);
            if (matchedTarget) { return matchedTarget; }
          }
          return null;
        };
        let _target = findTarget(previousState);
        if (_target) { _target.children = response.data.children; }
        return previousState;
      });
    }).catch((error) => {
      console.log(error);
      setUnknownError(true);
    }).finally(() => {
      setGeoTargetsLoading(previousState => {
        return { ...previousState, ...{ [target.id]: false } };
      });
    });
  };

  const loadSearchGeoTargets = (searchInput) => {
    if (!searchInput || searchInput.trim().length === 0) {
      return loadRootGeoTargets();
    }

    loadGeoTargets(axios.get('/geo_targets/search.json', { params: { q: searchInput }}), true);
  };

  const debouncedLoadSearchGeoTargets = useMemo(
    () => debounce(loadSearchGeoTargets, 500)
  , []);

  const loadBudgetAmountByCost = (cost, campaignId) => {
    if (cost === null) {
      setBudgetAmount('');
      return;
    }
    setUnknownError(false);
    axios.get(`/advertisers/${props.advertiser_id}/campaigns/${campaignId}/amount_by_cost.json`, { params: { cost: cost }}).then((response) => {
      setBudgetAmount(response.data);
    }).catch((error) => {
      console.log(error);
      setUnknownError(true);
    });
  };

  const debouncedLoadBudgetAmountByCost = useMemo(
    () => debounce(loadBudgetAmountByCost, 333)
  , []);

  const loadBudgetCostByAmount = (amount, campaignId) => {
    if (amount === null) {
      updateCampaign({ budget: '' });
      return;
    }
    setUnknownError(false);
    axios.get(`/advertisers/${props.advertiser_id}/campaigns/${campaignId}/cost_by_amount.json`, { params: { amount: amount }}).then((response) => {
      updateCampaign({ budget: response.data });
    }).catch((error) => {
      console.log(error);
      setUnknownError(true);
    });
  };

  const debouncedLoadBudgetCostByAmount = useMemo(
    () => debounce(loadBudgetCostByAmount, 333)
  , []);

  const loadBalances = () => {
    setBalancesLoading(true);
    setUnknownError(false);
    axios.get(`/advertisers/${props.advertiser_id}/balance_accounts.json`).then((response) => {
      setAvailFunds(response.data.avail_funds);
      if (response.data.credit_used === null || response.data.credit_limit === null) { return; }
      setAvailCredit(response.data.credit_limit - response.data.credit_used);
      setCreditLimit(response.data.credit_limit);
    }).catch((error) => {
      console.log(error);
      setUnknownError(true);
    }).finally(() => {
      setBalancesLoading(false);
    });
  };

  // input handlers
  const handleActiveCampaignTypeCategoryChange = (e) => {
    setActiveCampaignTypeCategory(e.target.value);
  };

  const handleCampaignTypeChange = (e) => {
    updateCampaign({ type: e.target.value });
  };

  const handleCampaignScheduledStartDateChange = (e) => {
    if (calculateScheduledEndDateByStartDateFn) {
      updateCampaign({ scheduled_start_date: e.target.value, scheduled_end_date: calculateScheduledEndDateByStartDateFn(e.target.value) });
      return;
    }
    updateCampaign({ scheduled_start_date: e.target.value });
  };

  const handleCampaignScheduledEndDateChange = (e) => {
    updateCampaign({ scheduled_end_date: e.target.value });
  };

  const handleSectionTargetingChange = (e) => {
    updateCampaign({ section_targeting: e.target.value });
  };

  const handleDeviceTargetingChange = (e) => {
    updateCampaign({ device_targeting: e.target.value === campaign.device_targeting ? null : e.target.value });
  };

  const handleGeoTargetingChange = (target) => {
    const id = target.id;
    if (campaign.geo_targeting && campaign.geo_targeting.includes(id)) {
      updateCampaign({ geo_targeting: campaign.geo_targeting.filter((t) => t !== id ) });
    } else {
      let geo_targeting = campaign.geo_targeting || [];
      geo_targeting = geo_targeting.filter((tid) => {
        if (!geoTargetLookup[tid]) { return true; }
        if (!geoTargetLookup[tid].ancestor_ids) { return true; }
        if (geoTargetLookup[tid].ancestor_ids.length <= 0) { return true; }
        if (geoTargetLookup[tid].ancestor_ids.includes(id)) { return false; } // remove child target if the parent or ancestor target selected
        return true;
      });
      geo_targeting.push(id);
      setGeoTargetLookup(previousState => {
        return { ...previousState, [id]: (({ name, country_code, type, ancestor_ids }) => ({ name, country_code, type, ancestor_ids }))(target) };
      });
      updateCampaign({ geo_targeting: geo_targeting });
    }
  };

  const handleGeoTargetingExpand = (target) => {
    if (!geoTargetsExpanded[target.id]) {
      loadGeoTarget(target);
    }
    setGeoTargetsExpanded(previousState => {
      return { ...previousState, [target.id]: !previousState[target.id] }
    });
  };

  const handleGeoTargetingClear = () => {
    updateCampaign({ geo_targeting: null });
  };

  const handleCampaignBudgetChange = (e) => {
    const budget = (e.target.value && e.target.value.trim().length > 0) ? parseFloat(e.target.value) : null;
    updateCampaign({ budget: budget });
    debouncedLoadBudgetAmountByCost(budget, campaign.id)
  };

  const handleCampaignAmountChange = (e) => {
    const amount = (e.target.value && e.target.value.trim().length > 0) ? parseInt(e.target.value) : null;
    setBudgetAmount(amount);
    debouncedLoadBudgetCostByAmount(amount, campaign.id);
  };

  const handleCreativeUploadFile = (creative, fieldName, fileOrFiles, lineItem, _campaign, {resetFields}={}) => {
    setShouldShowCreativeHasErrors(false);
    setUnknownCreativeErrors(prevState => ({ ...prevState, [creative.id]: null }));
    setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: null }));
    setCreativeErrors(prevState => ({ ...prevState, [creative.id]: null }));

    let endpoint = `/advertisers/${props.advertiser_id}/campaigns/${_campaign.id}/line_items/${lineItem.id}/creatives/${creative.id}.json`;
    const formData = new FormData();
    formData.append('authenticity_token', document.head.querySelector('meta[name="csrf-token"]').content);
    formData.append('creative[id]', creative.id);
    formData.append(`creative[${fieldName}]`, fileOrFiles);
    for (const resetField of resetFields || []) {
      formData.append(`creative[${resetField}]`, null);
    }

    setCreativeFileUploading(prevState => ({ ...prevState, [`${creative.id}-${fieldName}`]: true }));
    return axios.patch(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      let _lineItem, _errors, creativeData, lineItemData;
      (() => {
        const { line_item, errors, ..._creativeData } = response.data || {};
        _lineItem = line_item;
        _errors = errors;
        creativeData = _creativeData;
      })()
      const _creativeErrors = _errors || null;
      (() => {
        const { errors, ..._lineItemData } = _lineItem || {};
        _errors = errors;
        lineItemData = _lineItemData
      })()
      const _lineItemErrors = _errors || null;
      setCampaign(previousState => {
        let newState = { ...previousState };
        for (const lineItem of newState.line_items || []) {
          for (const _creative of lineItem.creatives || []) {
            if (_creative.id !== creative.id) { continue; }
            // found the matched creative
            if (!creativeData[fieldName]) { return previousState; }
            let _data = { [fieldName]: creativeData[fieldName] };
            for (const resetField of resetFields || []) {
              _data[resetField] = null;
            }
            Object.assign(lineItem, lineItemData);
            Object.assign(_creative, _data);
            return newState;
          }
        }
        return previousState;
      });
      if (_creativeErrors) {
        setCreativeErrors(prevState => ({ ...prevState, [creative.id]: _creativeErrors }));
      }
      if (_lineItemErrors) {
        setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: _lineItemErrors }));
      }
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 422) {
        const { line_item, ..._creativeErrors } = error.response.data || {};
        const _lineItemErrors = (line_item || {}).errors || null;
        setCreativeErrors(prevState => ({ ...prevState, [creative.id]: _creativeErrors }));
        setLineItemErrors(prevState => ({ ...prevState, [lineItem.id]: _lineItemErrors }));
        return;
      }
      setUnknownCreativeErrors(prevState => ({ ...prevState, [creative.id]: true }));
    }).finally(() => {
      setCreativeFileUploading(prevState => ({ ...prevState, [`${creative.id}-${fieldName}`]: false }));
    });
  };

  const handleCreativeTabChange = (key, value) => {
    setActiveCreativeTab((prevState) => {
      let newState = { ...prevState };
      newState[key] = value;
      return newState;
    });
  };

  const handleReviewSubmissionConfirmationClosed = (e) => {
    setShowCampaignInlineSubmissionConfirmedDialog(false);
    location.replace(`/advertisers/${props.advertiser_id}/campaigns/${props.campaign_id || campaign.id}`);
  };

  const campaignGeoTargeting = campaign.geo_targeting || [];

  const geoTargetsComponent = (id, targets, {extraClassName, checkedAndDisabled}={}) => {
    return <ul className={`no-bullet ${extraClassName}`}>
      {geoTargetsLoading[id] &&
        <li key={id}>
          <div className="location-select">
            <div className="checkbox">
              <label style={{pointerEvents: 'none'}}><span className="txt"><span className="quiet">Loading...</span></span> </label>
            </div>
          </div>
        </li>
      }
      {!geoTargetsLoading[id] && (!targets || targets.length === 0) &&
        <li key={id}>
          <div className="location-select">
            <div className="checkbox">
              <label style={{pointerEvents: 'none'}}><span className="txt"><span className="quiet">No geo targets found</span></span> </label>
            </div>
          </div>
        </li>
      }
      {targets && targets.map((target) => {
        const checked = checkedAndDisabled || campaignGeoTargeting.includes(target.id);
        return <li className={geoTargetsExpanded[target.id] ? 'expand' : ''} key={target.id}>
          <div className="location-select">
            <div className="checkbox">
              <input type="checkbox" id={`geo-target-${target.id}`} checked={checked} disabled={checkedAndDisabled || campaignAttributesLocked} onChange={() => handleGeoTargetingChange(target)} />
              <label htmlFor={`geo-target-${target.id}`}><span className="txt">{target.name} {geoTargetShowCountryCode && `- ${target.country_code} `}<span className="quiet">({target.type})</span></span> <span className="icon"></span></label>
              {!target.leaf &&
                <button className="flat-button js-tree-toggle" type="button" onClick={() => handleGeoTargetingExpand(target)}><img src={ImageDownAngle} alt="" /></button>
              }
            </div>
          </div>
          {geoTargetsExpanded[target.id] && geoTargetsComponent(target.id, target.children, {checkedAndDisabled: checked})}
        </li>
      })}
    </ul>
  };

  const creativeComponent = (creative, lineItem) => {
    let creativeKlass = creative.type || lineItem.creative_klass;

    const header = <div className="creative-copy-header">
      <button className="spin danger lg js-remove" type="button" onClick={() => deleteCreative(campaign, lineItem, creative)}>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13.866l6.433-6.433M13.866 1L7.433 7.433m0 0L1.134 1.134m6.299 6.299L14 14" stroke="currentColor"></path>
        </svg>
      </button>
      <ContentEditable
        html={creative.name || ''}
        onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, name: he.decode(e.target.value) })}
        tagName='h3'
      />
    </div>

    const landingPageUrlField = <div className="form-row">
      <label htmlFor={`landing-page-url-${lineItem.id}-${creative.id}`}>Landing Page URL *</label>
      <input type="url" id={`landing-page-url-${lineItem.id}-${creative.id}`} placeholder="https://your-site.com/landing-page" value={creative.landing_page_url || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, landing_page_url: e.target.value })} />
      {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].landing_page_url &&
        <ErrorBox message={`Landing page URL ${creativeErrors[creative.id || creative.uuid].landing_page_url.join('; And ')}.`} />
      }
    </div>

    const unknownCreativeErrorsComp = unknownCreativeErrors && unknownCreativeErrors[creative.id || creative.uuid] ? <ErrorBox message={'Oops! Something went wrong. Please try again later.'} /> : null;
    const creativeDeletionErrorsComp = creativeDeletionErrors && creativeDeletionErrors[creative.id || creative.uuid] ? <ErrorBox message={'Oops! Failed to remove the creative. Please try again later.'} /> : null;

    if (creativeKlass === 'DisplayCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          {landingPageUrlField}
          <div className="radio-toggle" data-active={activeCreativeTab[`${creative.id}-content-type`] || (creative.tag_code ? 2 : 1)}>
            <div className="form-row">
              <fieldset>
                <legend className="label">Creative Type</legend>
                <div>
                  <span className="radio outline mr10">
                  <input type="radio" id={`content-type-image-${lineItem.id}-${creative.id}`} name={`content-type-radio-${lineItem.id}-${creative.id}`} value="1" checked={(!activeCreativeTab[`${creative.id}-content-type`] && !creative.tag_code) || activeCreativeTab[`${creative.id}-content-type`] === 1} onChange={() => handleCreativeTabChange(`${creative.id}-content-type`, 1)} />
                  <label htmlFor={`content-type-image-${lineItem.id}-${creative.id}`}>
                  <span className="txt">Image</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                  <span className="radio outline ">
                  <input type="radio" id={`content-type-tag-${lineItem.id}-${creative.id}`} name={`content-type-radio-${lineItem.id}-${creative.id}`} value="2" checked={(!activeCreativeTab[`${creative.id}-content-type`] && creative.tag_code) || activeCreativeTab[`${creative.id}-content-type`] === 2} onChange={() => handleCreativeTabChange(`${creative.id}-content-type`, 2)} />
                  <label htmlFor={`content-type-tag-${lineItem.id}-${creative.id}`}>
                  <span className="txt">Tag</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                </div>
              </fieldset>
            </div>
            <div className="radio-toggle-groups">
              <div>
                <div>
                  <div className="form-row">
                    <div className="note">{lineItem.required_sizes.map(size => `${size[0]}x${size[1]}`).join(', ')} (Optional: {lineItem.optional_sizes.map(size => `${size[0]}x${size[1]}`).join(', ')})</div>
                    <div className="mt20">
                      <span className="file-button">
                      <input type="file" id={`image-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'image', e.target.files[0], lineItem, campaign, { resetFields: ['tag_size', 'tag_code'] })} disabled={creativeFileUploading[`${creative.id}-image`]} />
                      <label htmlFor={`image-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-image`] ? 'Uploading...' : 'Upload image'}</span></label>
                      </span>
                      {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
                    </div>
                    <ul className="gallery-3_0 no-bullet mt20">
                      {creative.image && creative.image.url &&
                        <li>
                          <div className="img-box center-align">
                            <a href={creative.image.url} target="_blank">
                            <img src={creative.image.url} alt="" />
                            <span className="title">{creative.image.filename}</span>
                            </a>
                          </div>
                        </li>
                      }
                    </ul>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image &&
                      <ErrorBox message={`Image ${creativeErrors[creative.id || creative.uuid].image.join('; And ')}.`} />
                    }
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image_xor_tag_code &&
                      <ErrorBox message={`${creativeErrors[creative.id || creative.uuid].image_xor_tag_code.join('; And ')}.`} />
                    }
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="form-row">
                    <fieldset>
                      <div>
                        {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].tag_size &&
                          <ErrorBox message={`Tag size ${creativeErrors[creative.id || creative.uuid].tag_size.join('; And ')}.`} />
                        }
                        {(lineItem.required_sizes || []).map(size => [`${size[0]}x${size[1]}`, false]).concat((lineItem.optional_sizes || []).map(size => [`${size[0]}x${size[1]}`, true])).map((size) => {
                          return <span className="radio outline mr10" key={size[0]}>
                            <input type="radio" id={`tag-size-${size[0]}-${lineItem.id}-${creative.id}`} name={`tag-size-${lineItem.id}-${creative.id}`} value={size[0]} checked={creative.tag_size === size[0]} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, tag_size: e.target.value, image: null })} />
                            <label htmlFor={`tag-size-${size[0]}-${lineItem.id}-${creative.id}`}>
                            <span className="txt">{size[0]}{size[1] && <span className="quiet">&nbsp;(optional)</span>}</span>
                            <span className="icon"></span>
                            </label>
                          </span>
                        })}
                      </div>
                    </fieldset>
                    <label className="visually-hidden" htmlFor={`tag-code-${lineItem.id}-${creative.id}`}>Tag Code</label>
                    <textarea id={`tag-code-${lineItem.id}-${creative.id}`} cols="30" rows="7" placeholder="Paste your code here …" value={creative.tag_code || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, tag_code: e.target.value, image: null })}></textarea>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].tag_code &&
                      <ErrorBox message={`Tag ${creativeErrors[creative.id || creative.uuid].tag_code.join('; And ')}.`} />
                    }
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image_xor_tag_code &&
                      <ErrorBox message={`${creativeErrors[creative.id || creative.uuid].image_xor_tag_code.join('; And ')}.`} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <label htmlFor={`imp-tracking-pixel-${lineItem.id}-${creative.id}`}>Custom Impression Tracking Pixel (Optional)</label>
            <input type="url" id={`imp-tracking-pixel-${lineItem.id}-${creative.id}`} placeholder="https://ad.doubleclick.net/ddm/trackimp/N1234.567..." value={creative.impression_pixel_url || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, impression_pixel_url: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].impression_pixel_url &&
              <ErrorBox message={`Impression pixel URL ${creativeErrors[creative.id || creative.uuid].impression_pixel_url.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>
    }

    if (creativeKlass === 'NativeCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="form-row">
            <label htmlFor={`brand-name-${lineItem.id}-${creative.id}`}>Brand Name *</label>
            <input type="text" id={`brand-name-${lineItem.id}-${creative.id}`} placeholder="Your brand name" value={creative.brand_name || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, brand_name: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].brand_name &&
              <ErrorBox message={`Brand name ${creativeErrors[creative.id || creative.uuid].brand_name.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`ad-title-${lineItem.id}-${creative.id}`}>Title *</label>
            <input type="text" id={`ad-title-${lineItem.id}-${creative.id}`} placeholder="Your native ad title" value={creative.title || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, title: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].title &&
              <ErrorBox message={`Title ${creativeErrors[creative.id || creative.uuid].title.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`ad-desc-${lineItem.id}-${creative.id}`}>Description *</label>
            <textarea id={`ad-desc-${lineItem.id}-${creative.id}`} cols="30" rows="7" placeholder="Your native ad description" value={creative.description || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, description: e.target.value })}></textarea>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].description &&
              <ErrorBox message={`Description ${creativeErrors[creative.id || creative.uuid].description.join('; And ')}.`} />
            }
          </div>
          {landingPageUrlField}
          <div className="form-row">
            <div className="label">Image *</div>
            <div className="note">450x300px (3:2 ratio)</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`image-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'image', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-image`]} />
              <label htmlFor={`image-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-image`] ? 'Uploading...' : 'Upload image'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="gallery-3_0 no-bullet mt20">
              {creative.image && creative.image.url &&
                <li>
                  <div className="img-box center-align">
                    <a href={creative.image.url} target="_blank">
                    <img src={creative.image.url} alt="" />
                    <span className="title">{creative.image.filename}</span>
                    </a>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image &&
              <ErrorBox message={`Image ${creativeErrors[creative.id || creative.uuid].image.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`imp-tracking-pixel-${lineItem.id}-${creative.id}`}>Custom Impression Tracking Pixel (Optional)</label>
            <input type="url" id={`imp-tracking-pixel-${lineItem.id}-${creative.id}`} placeholder="https://ad.doubleclick.net/ddm/trackimp/N1234.567..." value={creative.impression_pixel_url || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, impression_pixel_url: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].impression_pixel_url &&
              <ErrorBox message={`Impression pixel URL ${creativeErrors[creative.id || creative.uuid].impression_pixel_url.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>
    }
    
    if (creativeKlass === 'SocialCardCreative') {
      const ctaFields = <div>
        <div className="form-grid-3 form-row">
          <div>
            <label htmlFor={`cta-button-text-${lineItem.id}-${creative.id}`}>CTA Button Text</label>
            <input type="text" id={`cta-button-text-${lineItem.id}-${creative.id}`} placeholder="Shop Now!" value={creative.cta_button_text || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, cta_button_text: e.target.value })} />
          </div>
          <div>
            <label htmlFor={`cta-button-color-${lineItem.id}-${creative.id}`}>CTA Button Color</label>
            <input type="text" id={`cta-button-color-${lineItem.id}-${creative.id}`} placeholder="#2D1B9E" value={creative.cta_button_color || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, cta_button_color: e.target.value })} />
          </div>
          <div>
            <label htmlFor={`cta-text-color-${lineItem.id}-${creative.id}`}>CTA Text Color</label>
            <input type="text" id={`cta-text-color-${lineItem.id}-${creative.id}`} placeholder="#FFF" value={creative.cta_text_color || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, cta_text_color: e.target.value })} />
          </div>
        </div>
        {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].cta_button_text &&
          <ErrorBox message={`CTA Button Text ${creativeErrors[creative.id || creative.uuid].cta_button_text.join('; And ')}.`} />
        }
        {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].cta_button_color &&
          <ErrorBox message={`CTA Button Color ${creativeErrors[creative.id || creative.uuid].cta_button_color.join('; And ')}.`} />
        }
        {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].cta_text_color &&
          <ErrorBox message={`CTA Text Color ${creativeErrors[creative.id || creative.uuid].cta_text_color.join('; And ')}.`} />
        }
      </div>;

      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="radio-toggle" data-active={activeCreativeTab[`${creative.id}-facebook-type`] || (creative.facebook_page_url ? 2 : 1)}>
            <div className="form-row">
              <fieldset>
                <legend className="label">Have a Facebook Post URL?</legend>
                <div>
                  <span className="radio outline mr10">
                  <input type="radio" id={`facebook-type-post-${lineItem.id}-${creative.id}`} name={`facebook-type-radio-${lineItem.id}-${creative.id}`} value="1" checked={(!activeCreativeTab[`${creative.id}-facebook-type`] && !creative.facebook_page_url) || activeCreativeTab[`${creative.id}-facebook-type`] === 1} onChange={() => handleCreativeTabChange(`${creative.id}-facebook-type`, 1)} />
                  <label htmlFor={`facebook-type-post-${lineItem.id}-${creative.id}`}>
                  <span className="txt">Yes</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                  <span className="radio outline ">
                  <input type="radio" id={`facebook-type-page-${lineItem.id}-${creative.id}`} name={`facebook-type-radio-${lineItem.id}-${creative.id}`} value="2" checked={(!activeCreativeTab[`${creative.id}-facebook-type`] && creative.facebook_page_url) || activeCreativeTab[`${creative.id}-facebook-type`] === 2} onChange={() => handleCreativeTabChange(`${creative.id}-facebook-type`, 2)} />
                  <label htmlFor={`facebook-type-page-${lineItem.id}-${creative.id}`}>
                  <span className="txt">No</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                </div>
              </fieldset>
              {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].post_xor_page_url &&
                <ErrorBox message={`${creativeErrors[creative.id || creative.uuid].post_xor_page_url.join('; And ')}.`} />
              }
            </div>
            <div className="radio-toggle-groups">
              <div>
                <div>
                  <div className="form-row">
                    <label htmlFor={`facebook-post-url-${lineItem.id}-${creative.id}`}>Facebook Post URL *</label>
                    <input type="url" id={`facebook-post-url-${lineItem.id}-${creative.id}`} placeholder="https://facebook.com/xxx/posts/xxx" value={creative.facebook_post_url || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, facebook_post_url: e.target.value, facebook_page_url: null, brand_name: null, description: null, logo: null, image: null })} />
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].facebook_post_url &&
                      <ErrorBox message={`Facebook Post URL ${creativeErrors[creative.id || creative.uuid].facebook_post_url.join('; And ')}.`} />
                    }
                  </div>
                </div>
                <div>{landingPageUrlField}</div>
                {ctaFields}
              </div>
              <div>
                <div>
                  <div className="form-row">
                    <label htmlFor={`facebook-page-url-${lineItem.id}-${creative.id}`}>Facebook Page URL *</label>
                    <input type="url" id={`facebook-page-url-${lineItem.id}-${creative.id}`} placeholder="https://facebook.com/xxx" value={creative.facebook_page_url || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, facebook_page_url: e.target.value, facebook_post_url: null })} />
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].facebook_page_url &&
                      <ErrorBox message={`Facebook Page URL ${creativeErrors[creative.id || creative.uuid].facebook_page_url.join('; And ')}.`} />
                    }
                  </div>
                </div>
                <div>
                  <div className="form-row">
                    <label htmlFor={`brand-name-${lineItem.id}-${creative.id}`}>Facebook Page Brand Name *</label>
                    <input type="text" id={`brand-name-${lineItem.id}-${creative.id}`} placeholder="Your brand name" value={creative.brand_name || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, brand_name: e.target.value })} />
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].brand_name &&
                      <ErrorBox message={`Facebook Page Brand name ${creativeErrors[creative.id || creative.uuid].brand_name.join('; And ')}.`} />
                    }
                  </div>
                </div>
                <div>
                  <div className="form-row">
                    <label htmlFor={`ad-caption-${lineItem.id}-${creative.id}`}>Caption *</label>
                    <textarea id={`ad-caption-${lineItem.id}-${creative.id}`} cols="30" rows="7" placeholder="" value={creative.caption || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, caption: e.target.value })}></textarea>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].caption &&
                      <ErrorBox message={`Caption ${creativeErrors[creative.id || creative.uuid].caption.join('; And ')}.`} />
                    }
                  </div>
                </div>
                <div>
                  {landingPageUrlField}
                </div>
                {ctaFields}
                <div>
                  <div className="form-row">
                    <label htmlFor={`logo-${lineItem.id}-${creative.id}`}>Facebook Page Logo Image *</label>
                    <div className="note">100x100px (1:1 ratio)</div>
                    <div className="mt20">
                      <span className="file-button">
                      <input type="file" id={`logo-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'logo', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-logo`]} />
                      <label htmlFor={`logo-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-logo`] ? 'Uploading...' : 'Upload image'}</span></label>
                      </span>
                      {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
                    </div>
                    <ul className="gallery-3_0 no-bullet mt20">
                      {creative.logo && creative.logo.url &&
                        <li>
                          <div className="img-box center-align">
                            <a href={creative.logo.url} target="_blank">
                            <img src={creative.logo.url} alt="" />
                            <span className="title">{creative.logo.filename}</span>
                            </a>
                          </div>
                        </li>
                      }
                    </ul>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].logo &&
                      <ErrorBox message={`Logo ${creativeErrors[creative.id || creative.uuid].logo.join('; And ')}.`} />
                    }
                  </div>
                </div>
                <div>
                  <div className="form-row">
                    <label htmlFor={`image-${lineItem.id}-${creative.id}`}>Image{/* or Video*/} *</label>
                    <div className="note">Image Size: 450x300px (3:2 ratio){/* <br />Video Clip: Minimum 15 seconds, Maximum 5 minutes (16:9 or 4:3 ratio)*/}</div>
                    <div className="mt20">
                      <span className="file-button">
                      <input type="file" id={`image-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'image', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-image`]} />
                      <label htmlFor={`image-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-image`] ? 'Uploading...' : 'Upload image'}</span></label>
                      </span>
                      {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
                    </div>
                  </div>
                  <ul className="gallery-3_0 no-bullet mt20">
                    {creative.image && creative.image.url &&
                      <li>
                        <div className="img-box center-align">
                          <a href={creative.image.url} target="_blank">
                          <img src={creative.image.url} alt="" />
                          <span className="title">{creative.image.filename}</span>
                          </a>
                        </div>
                      </li>
                    }
                  </ul>
                  {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image &&
                    <ErrorBox message={`Image ${creativeErrors[creative.id || creative.uuid].image.join('; And ')}.`} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    }

    if (creativeKlass === 'BrandedContentCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="form-row">
            <label htmlFor={`logo-${lineItem.id}-${creative.id}`}>Logo Image *</label>
            <div className="note">355x71px (7:1 ratio)</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`logo-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'logo', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-logo`]} />
              <label htmlFor={`logo-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-logo`] ? 'Uploading...' : 'Upload image'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="gallery-3_0 no-bullet mt20">
              {creative.logo && creative.logo.url &&
                <li>
                  <div className="img-box center-align">
                    <a href={creative.logo.url} target="_blank">
                    <img src={creative.logo.url} alt="" />
                    <span className="title">{creative.logo.filename}</span>
                    </a>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].logo &&
              <ErrorBox message={`Logo ${creativeErrors[creative.id || creative.uuid].logo.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`doc-${lineItem.id}-${creative.id}`}>Document *</label>
            <div className="note">Format: .doc or .docx file</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`doc-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'doc', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-doc`]} />
              <label htmlFor={`doc-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-doc`] ? 'Uploading...' : 'Upload file'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="no-bullet mt20">
              {creative.doc && creative.doc.url &&
                <li>
                  <div className="file-box">
                    <div className="justify-row">
                      <a href={creative.doc.url} target="_blank">
                      <img src={ImageFile} alt="" />
                      <span className="title fz14">{creative.doc.filename}</span>
                      </a>
                    </div>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].doc &&
              <ErrorBox message={`Document ${creativeErrors[creative.id || creative.uuid].doc.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>
    }

    if (creativeKlass === 'FacebookPostCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          {landingPageUrlField}
          <div className="form-row">
            <label htmlFor={`brand-name-${lineItem.id}-${creative.id}`}>Brand Name *</label>
            <input type="text" id={`brand-name-${lineItem.id}-${creative.id}`} placeholder="Your brand name" value={creative.brand_name || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, brand_name: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].brand_name &&
              <ErrorBox message={`Brand name ${creativeErrors[creative.id || creative.uuid].brand_name.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`ad-message-${lineItem.id}-${creative.id}`}>Message *</label>
            <textarea id={`ad-message-${lineItem.id}-${creative.id}`} cols="30" rows="7" placeholder="" value={creative.message || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, message: e.target.value })}></textarea>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].message &&
              <ErrorBox message={`Message ${creativeErrors[creative.id || creative.uuid].message.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor="facebook-post_image-video">Image{/* or Video*/}</label>
            <div className="note">Image Size: 1200x800px (3:2 ratio){/* <br />Video Clip: Minimum 15 seconds, Maximum 5 minutes (16:9 or 4:3 ratio)*/}</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`image-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'image', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-image`]} />
              <label htmlFor={`image-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-image`] ? 'Uploading...' : 'Upload image'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="gallery-3_0 no-bullet mt20">
              {creative.image && creative.image.url &&
                <li>
                  <div className="img-box center-align">
                    <a href={creative.image.url} target="_blank">
                    <img src={creative.image.url} alt="" />
                    <span className="title">{creative.image.filename}</span>
                    </a>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image &&
              <ErrorBox message={`Image ${creativeErrors[creative.id || creative.uuid].image.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>
    }

    if (creativeKlass === 'SponsoredEblastCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="form-row">
            <label htmlFor={`brand-name-${lineItem.id}-${creative.id}`}>Brand Name *</label>
            <input type="text" id={`brand-name-${lineItem.id}-${creative.id}`} placeholder="Your brand name" value={creative.brand_name || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, brand_name: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].brand_name &&
              <ErrorBox message={`Brand name ${creativeErrors[creative.id || creative.uuid].brand_name.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`ad-message-${lineItem.id}-${creative.id}`}>Message *</label>
            <textarea id={`ad-message-${lineItem.id}-${creative.id}`} cols="30" rows="7" placeholder="" value={creative.message || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, message: e.target.value })}></textarea>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].message &&
              <ErrorBox message={`Message ${creativeErrors[creative.id || creative.uuid].message.join('; And ')}.`} />
            }
          </div>
          {landingPageUrlField}
          <div className="form-row">
            <label htmlFor={`test-email-addresses-${lineItem.id}-${creative.id}`}>Test Email Addresses For eBlast Confirmation</label>
            <div className="note">Up to 10 Addresses, separate with comma</div>
            <input type="text" id={`test-email-addresses-${lineItem.id}-${creative.id}`} placeholder="email-1@email.com, email-2@email.com, email-3@email.com" value={creative.test_email_addresses || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, test_email_addresses: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].test_email_addresses &&
              <ErrorBox message={`Test Email Addresses ${creativeErrors[creative.id || creative.uuid].test_email_addresses.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor="sponsored-eblast_image">Image *</label>
            <div className="note">Image Size: 600x400px (3:2 ratio)</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`image-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'image', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-image`]} />
              <label htmlFor={`image-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-image`] ? 'Uploading...' : 'Upload image'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="gallery-3_0 no-bullet mt20">
              {creative.image && creative.image.url &&
                <li>
                  <div className="img-box center-align">
                    <a href={creative.image.url} target="_blank">
                    <img src={creative.image.url} alt="" />
                    <span className="title">{creative.image.filename}</span>
                    </a>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].image &&
              <ErrorBox message={`Image ${creativeErrors[creative.id || creative.uuid].image.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>;
    }

    if (creativeKlass === 'DedicatedEblastCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="form-row">
            <label htmlFor={`brand-name-${lineItem.id}-${creative.id}`}>Brand Name *</label>
            <input type="text" id={`brand-name-${lineItem.id}-${creative.id}`} placeholder="Your brand name" value={creative.brand_name || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, brand_name: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].brand_name &&
              <ErrorBox message={`Brand name ${creativeErrors[creative.id || creative.uuid].brand_name.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`subject-${lineItem.id}-${creative.id}`}>Subject *</label>
            <input type="text" id={`subject-${lineItem.id}-${creative.id}`} placeholder="Your email subject" value={creative.subject || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, subject: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].subject &&
              <ErrorBox message={`Subject ${creativeErrors[creative.id || creative.uuid].subject.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`test-email-addresses-${lineItem.id}-${creative.id}`}>Test Email Addresses For eBlast Confirmation</label>
            <div className="note">Up to 10 Addresses, separate with comma</div>
            <input type="text" id={`test-email-addresses-${lineItem.id}-${creative.id}`} placeholder="email-1@email.com, email-2@email.com, email-3@email.com" value={creative.test_email_addresses || ''} onChange={(e) => updateCreative({ idOrUuid: creative.id || creative.uuid, test_email_addresses: e.target.value })} />
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].test_email_addresses &&
              <ErrorBox message={`Test Email Addresses ${creativeErrors[creative.id || creative.uuid].test_email_addresses.join('; And ')}.`} />
            }
          </div>
          <div className="form-row">
            <label htmlFor={`doc-${lineItem.id}-${creative.id}`}>Document *</label>
            <div className="note">Format: .doc or .docx file</div>
            <div className="mt20">
              <span className="file-button">
              <input type="file" id={`doc-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'doc', e.target.files[0], lineItem, campaign)} disabled={creativeFileUploading[`${creative.id}-doc`]} />
              <label htmlFor={`doc-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-doc`] ? 'Uploading...' : 'Upload file'}</span></label>
              </span>
              {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
            </div>
            <ul className="no-bullet mt20">
              {creative.doc && creative.doc.url &&
                <li>
                  <div className="file-box">
                    <div className="justify-row">
                      <a href={creative.doc.url} target="_blank">
                      <img src={ImageFile} alt="" />
                      <span className="title fz14">{creative.doc.filename}</span>
                      </a>
                    </div>
                  </div>
                </li>
              }
            </ul>
            {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].doc &&
              <ErrorBox message={`Document ${creativeErrors[creative.id || creative.uuid].doc.join('; And ')}.`} />
            }
          </div>
        </div>
      </fieldset>;
    }

    if (creativeKlass === 'PodcastEmbeddedCreative') {
      return <fieldset className="creative-copy" key={creative.id || creative.uuid}>
        {header}
        <div className="creative-copy-content">
          {unknownCreativeErrorsComp}
          {creativeDeletionErrorsComp}
          <div className="radio-toggle" data-active={activeCreativeTab[`${creative.id}-content-type`] || (creative.doc ? 2 : 1)}>
            <div className="form-row">
              <label htmlFor={`content-type-${lineItem.id}-${creative.id}`}>Audio/Text file *</label>
              <fieldset>
                <div>
                  <span className="radio outline mr10">
                  <input type="radio" id={`content-type-audio-${lineItem.id}-${creative.id}`} name={`content-type-audio-${lineItem.id}-${creative.id}`} value="1" checked={(!activeCreativeTab[`${creative.id}-content-type`] && !creative.doc) || activeCreativeTab[`${creative.id}-content-type`] === 1} onChange={() => handleCreativeTabChange(`${creative.id}-content-type`, 1)} />
                  <label htmlFor={`content-type-audio-${lineItem.id}-${creative.id}`}>
                  <span className="txt">Audio</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                  <span className="radio outline ">
                  <input type="radio" id={`content-type-doc-${lineItem.id}-${creative.id}`} name={`content-type-doc-${lineItem.id}-${creative.id}`} value="2" checked={(!activeCreativeTab[`${creative.id}-content-type`] && creative.doc) || activeCreativeTab[`${creative.id}-content-type`] === 2} onChange={() => handleCreativeTabChange(`${creative.id}-content-type`, 2)} />
                  <label htmlFor={`content-type-doc-${lineItem.id}-${creative.id}`}>
                  <span className="txt">Text</span>
                  <span className="icon"></span>
                  </label>
                  </span>
                </div>
              </fieldset>
            </div>
            <div className="radio-toggle-groups">
              <div>
                <div>
                  <div className="form-row">
                    <div className="note">Audio: 30 seconds, MP3, M4A, etc</div>
                    <div className="mt20">
                      <span className="file-button">
                      <input type="file" id={`audio-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'audio', e.target.files[0], lineItem, campaign, { resetFields: ['doc'] })} disabled={creativeFileUploading[`${creative.id}-audio`]} />
                      <label htmlFor={`audio-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-audio`] ? 'Uploading...' : 'Upload file'}</span></label>
                      </span>
                      {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
                    </div>
                    <ul className="no-bullet mt20">
                      {creative.audio && creative.audio.url &&
                        <li>
                          <div className="file-box">
                            <div className="justify-row">
                              <a href={creative.audio.url} target="_blank">
                              <img src={ImageMp3} alt="" />
                              <span className="title fz14">{creative.audio.filename}</span>
                              </a>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].audio &&
                      <ErrorBox message={`Audio file ${creativeErrors[creative.id || creative.uuid].audio.join('; And ')}.`} />
                    }
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].audio_xor_doc &&
                      <ErrorBox message={`${creativeErrors[creative.id || creative.uuid].audio_xor_doc.join('; And ')}.`} />
                    }
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="form-row">
                    <div className="note">Text: maximum 500 letters including spaces; .doc or .docx file</div>
                    <div className="mt20">
                      <span className="file-button">
                      <input type="file" id={`doc-${lineItem.id}-${creative.id}`} onChange={(e) => handleCreativeUploadFile(creative, 'doc', e.target.files[0], lineItem, campaign, { resetFields: ['audio'] })} disabled={creativeFileUploading[`${creative.id}-doc`]} />
                      <label htmlFor={`doc-${lineItem.id}-${creative.id}`}><span className="btn fill lg">{creativeFileUploading[`${creative.id}-doc`] ? 'Uploading...' : 'Upload file'}</span></label>
                      </span>
                      {/*<button className="btn lg outline ml10" data-open-dialog="dialog-creative-library">Choose from your creative library</button>*/}
                    </div>
                    <ul className="no-bullet mt20">
                      {creative.doc && creative.doc.url &&
                        <li>
                          <div className="file-box">
                            <div className="justify-row">
                              <a href={creative.doc.url} target="_blank">
                              <img src={ImageFile} alt="" />
                              <span className="title fz14">{creative.doc.filename}</span>
                              </a>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].doc &&
                      <ErrorBox message={`Text file ${creativeErrors[creative.id || creative.uuid].doc.join('; And ')}.`} />
                    }
                    {creativeErrors && creativeErrors[creative.id || creative.uuid] && creativeErrors[creative.id || creative.uuid].audio_xor_doc &&
                      <ErrorBox message={`${creativeErrors[creative.id || creative.uuid].audio_xor_doc.join('; And ')}.`} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    }

    return '';
  };

  let stepComponent = [];

  if (!loading || currentStep === STEPS.SELECT_TYPE) {
    stepComponent = stepComponent.concat([
      <div className="constrain-md block-center">
      <h1 id="step-1" className="center-align">Campaign Setup</h1>
      <form key={11} className="form-stack mt40" aria-label="Campaign Setup field">
        <ul className="no-bullet">
          <li>
            <label htmlFor="campaign-name">Campaign Name *</label>
            <input id="campaign-name" type="text" value={campaign.name || ''} onChange={(e) => updateCampaign({ name: e.target.value })} disabled />
            {errors && errors.name &&
              <ErrorBox message={`Name ${errors.name.join('; And ')}.`} />
            }
          </li>
          <li>
            <div className="mt40">
              <div className="fz14">Select a Product or Package</div>
              <div className="mt10">
                {activeCampaignTypeCategory === "Single Product" &&
                  <span className="radio outline">
                  <input type="radio" name="product-package" id="single" value="Single Product" checked={activeCampaignTypeCategory === "Single Product"} />
                  <label htmlFor="single" style={{ cursor: 'default' }}><span className="txt">Single Product </span><span className="icon"></span></label>
                  </span>
                }
                {activeCampaignTypeCategory === "Package" &&
                  <span className="radio outline">
                  <input type="radio" name="product-package" id="package" value="Package" checked={activeCampaignTypeCategory === "Package"} />
                  <label htmlFor="package" style={{ cursor: 'default' }}><span className="txt">Package </span><span className="icon"></span></label>
                  </span>
                }
              </div>
              {errors && errors.type &&
                <ErrorBox message={`Campaign type ${errors.type.join('; And ')}.`} />
              }
              {activeCampaignTypeCategory === 'Single Product' &&
                <div className="single mt20">
                  <div className="fz14">Single product</div>
                  <ul className="gallery-3 mt10 no-bullet">
                    {singleProducts.filter((singleProduct) => campaign.type === singleProduct.class_name).map((singleProduct, i) => {
                      return <li key={i}>
                        <div className="checkbox card">
                          <input type="checkbox" name="single" checked={campaign.type === singleProduct.class_name} value={singleProduct.class_name} id={slugify(singleProduct.display_campaign_type)} />
                          <label htmlFor={slugify(singleProduct.display_campaign_type)} style={{ cursor: 'default' }}>
                          <span className="title-line">
                          <span className="title txt">{singleProduct.display_campaign_type}</span>
                          <span className="icon"></span>
                          </span>
                          <a href={singleProduct.details_url} className="details" target="_blank">More details</a>
                          </label>
                          <div className="note">{singleProduct.display_base_pricing}</div>
                        </div>
                      </li>
                    })}
                  </ul>
                </div>
              }
              {activeCampaignTypeCategory === 'Package' &&
                <div className="package mt20">
                  <div className="fz14">Package</div>
                  <ul className="gallery-3 gallery-packages mt10 no-bullet">
                    {pakages.filter((pakage) => campaign.type === pakage.class_name).map((pakage, i) => {
                      return <li key={i}>
                        <div className="radio package">
                          <input type="radio" name="package" checked={campaign.type === pakage.class_name} value={pakage.class_name} id={pakage.display_html_class} />
                          <label htmlFor={pakage.display_html_class} style={{ cursor: 'default' }}>
                          <span className={`header ${pakage.display_html_class}`}>
                          <span className="icon"></span>
                          <span className="title"><span className="package-name txt">{pakage.display_campaign_type}</span> <span className="duration">{pakage.display_duration}</span></span>
                          <span className="fz14 mt10">{pakage.display_note}</span>
                          </span>
                          {pakage.included_products.map((product, j) => {
                            return <span className="item-row" key={j}>
                              <span className="item">{product.display_campaign_type}</span>
                              <img src={ImageCheckedGreen} width="24" height="17" alt="" aria-hidden="true" />
                            </span>
                          })}
                          <span className="price-row">
                          <span className="price">Price {pakage.display_base_pricing}</span>
                          <span className="fz13 quiet mt10">Total value {pakage.display_total_value}</span><span className="fz13 quiet">Discount {pakage.display_discount}</span>
                          </span>
                          </label>
                        </div>
                      </li>
                    })}
                  </ul>
                </div>
              }
            </div>
          </li>
        </ul>
      </form></div>,
    ]);
  }

  if (!loading || currentStep === STEPS.SCHEDULE) {
    stepComponent.push(<div className="constrain-md block-center">
      <h1 id="step-2" className="center-align" style={{borderTop: '1px solid black', paddingTop: '.5em'}}>Schedule</h1>
      <form className="form-stack mt40" aria-label="Schedule field">
        <h2 className="h3">{scheduleType}</h2>
        <div className="note">Specify when your campaign should run.</div>
        <ul className="no-bullet mt20">
          <li>
            <div className="grid-2">
              <div>
                <label htmlFor="campaign-scheduled-start-date">Start Date *</label>
                <input id="campaign-scheduled-start-date" type="date" required value={campaign.scheduled_start_date} onChange={handleCampaignScheduledStartDateChange} min={minimumCampaignScheduledStartDate} disabled={campaignStartAndEndDateLocked} />
              </div>
              <div>
                <label htmlFor="campaign-scheduled-end-date">End Date *</label>
                <input id="campaign-scheduled-end-date" type="date" required value={campaign.scheduled_end_date} onChange={handleCampaignScheduledEndDateChange} min={minimumCampaignScheduledEndDate} disabled={campaignScheduledEndDateIsImmutable || campaignStartAndEndDateLocked} />
              </div>
            </div>
            <div className="note mt10">{ scheduledDuration ? `${scheduledDuration} days` : '' }</div>
          </li>
        </ul>
        {errors && errors.scheduled_start_date &&
          <ErrorBox message={`Start date ${errors.scheduled_start_date.join('; And ')}.`} />
        }
        {errors && errors.scheduled_end_date &&
          <ErrorBox message={`End date ${errors.scheduled_end_date.join('; And ')}.`} />
        }
      </form>
    </div>);
  }

  if (!loading || currentStep === STEPS.TARGETING) {
    stepComponent.push(<div className="constrain-md block-center">
      <h1 id="step-3" className="center-align" style={{borderTop: '1px solid black', paddingTop: '.5em'}}>Targeting</h1>
      <form className="form-stack mt40" aria-label="Targeting field" onSubmit={(e) => e.preventDefault()}>
        {'section_targeting' in campaign &&
          <section>
            <h2 className="h3">CP Sections</h2>
            <div className="note">Optional. +{currentCampaignTypeMeta.display_add_on_pricing['section_targeting']}</div>
            <div className="mt20">
              <select id="cp-sections" name="section_targeting" value={campaign.section_targeting} onChange={handleSectionTargetingChange} disabled={campaignAttributesLocked}>
                <option value="">-- Select --</option>
                {props.section_targeting_options.map((option, i) => {
                  return <option value={option} key={i}>{option}</option>
                })}
              </select>
            </div>
            {errors && errors.section_targeting &&
              <ErrorBox message={`Selected section ${errors.section_targeting.join('; And ')}.`} />
            }
          </section>
        }
        {'geo_targeting' in campaign &&
          <section className="mt60">
            <h2 className="h3">GEO targeting</h2>
            <div className="note">Description of GEO targeting. +{currentCampaignTypeMeta.display_add_on_pricing['geo_targeting']}</div>
            <div className="round-10 bg-light ph20 pv30 mt20">
              {geoTargetsComponent('roots', geoTargets, {extraClassName: 'tree-select'})}
              <input className="input-search mt20" type="search" placeholder="Search" value={geoTargetSearchInput || ''} onChange={(e) => setGeoTargetSearchInput(e.target.value)} disabled={campaignAttributesLocked} />
            </div>
            <ul className="geo-selected mt20 no-bullet">
              {campaignGeoTargeting.map((targetId, i) => {
                return geoTargetLookup[targetId] ? <li key={i}><span className="txt">{geoTargetLookup[targetId].type}: {geoTargetLookup[targetId].name} ({geoTargetLookup[targetId].country_code})</span>{campaignAttributesLocked ? null : <button className="spin light" onClick={() => handleGeoTargetingChange({ id: targetId, ...(geoTargetLookup[targetId] || {}) })}><img src={ImageClose} alt="" /></button>}</li> : null
              })}
            </ul>
            {campaignGeoTargeting.length > 0 &&
              <div className="fz13 mt20"><span>{campaignGeoTargeting.length} selected</span> <a className="c-link" href="javascript:void(0)" onClick={handleGeoTargetingClear}>Clear all</a></div>
            }
            {errors && errors.geo_targeting &&
              <ErrorBox message={`Geo targeting ${errors.geo_targeting.join('; And ')}.`} />
            }
          </section>
        }
        {'device_targeting' in campaign &&
          <section className="mt60">
            <h2 className="h3">Devices</h2>
            <div className="note">Specify which devices you want to target. +{currentCampaignTypeMeta.display_add_on_pricing['device_targeting']}</div>
            <div className="mt10">
              {props.device_targeting_options.map((option, i) => {
                return <span className={`checkbox outline ${i===0 ? '' : 'ml10'}`} key={i}>
                  <input type="checkbox" name="device_targeting" id={`device-targeting-${slugify(option)}`} value={option} checked={campaign.device_targeting === option} onChange={handleDeviceTargetingChange} disabled={campaignAttributesLocked} />
                  <label htmlFor={`device-targeting-${slugify(option)}`}><span className="txt">{option}</span> <span className="icon"></span></label>
                </span>
              })}
            </div>
            {errors && errors.device_targeting &&
              <ErrorBox message={`Selected device ${errors.device_targeting.join('; And ')}.`} />
            }
          </section>
        }
      </form>
      {!('section_targeting' in campaign) && !('geo_targeting' in campaign) && !('device_targeting' in campaign) &&
        <div className="mt60">There are no self-serve targeting options available for this type of campaign.</div>
      }
      <div className="mt60">For other targeting options, please <a className="tdu" href={`/advertisers/${props.advertiser_id}/cases/new`}>contact us</a>.</div>
    </div>);
  }

  if (!loading || currentStep === STEPS.BUDGET) {
    stepComponent.push(<div className="constrain-md block-center">
      <h1 id="step-4" className="center-align" style={{borderTop: '1px solid black', paddingTop: '.5em'}}>Budget</h1>
      {!campaign.budget_customizable ?
        <div className="mt60">The pricing is fixed for this type of campaign.</div>
        :
        <form className="form-stack mt40" aria-label="Budget field">
          {/*<div className="fz13 note">Maximum budget <strong>$15,000</strong>, Maximum impressions <strong>15,000</strong>, based on your targeting. To adjust targeting, please go back to make changes.</div>*/}
          <ul className="no-bullet mt20">
            <li>
              <div className="switch-wp">
                <button className="btn-switch flat-button" data-toggle="#budget-settings" type="button" aria-label="Switch budget and impressions" disabled style={{pointerEvents: 'none'}}><img src={ImageExchange} alt="" /></button>
                <div className="grid-2" id="budget-settings">
                  <div>
                    <label className="fz16 fwb" htmlFor="campaign-budget">Campaign Budget *</label>
                    <div className="note mb10">Set your campaign's total budget. (Min. $500)</div>
                    <input id="campaign-budget" type="number" min="500" max="5000" step="0.01" value={campaign.budget} placeholder="$0.00" onChange={handleCampaignBudgetChange} required="" disabled={campaignAttributesLocked} />
                  </div>
                  <div>
                    <label className="fz16 fwb" htmlFor="campaign-amount">Campaign Impressions</label>
                    <div className="note mb10">Set your campaign's total impressions.</div>
                    <input type="number" min="1000" step="1" placeholder="0" id="campaign-amount" value={budgetAmount} onChange={handleCampaignAmountChange} disabled={campaignAttributesLocked} />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="mt30">For campaigns over $5,000, please <a className="tdu" href={`/advertisers/${props.advertiser_id}/cases/new`}>contact us</a>.</div>
          {errors && errors.budget &&
            <ErrorBox message={`Budget ${errors.budget.join('; And ')}.`} />
          }
        </form>
      }
    </div>);
  }

  if (!loading || currentStep === STEPS.PAYMENT) {
    stepComponent.push(<div className="constrain-md block-center">
      <h1 id="step-5" className="center-align" style={{borderTop: '1px solid black', paddingTop: '.5em'}}>Payment</h1>
      <div className="input-amount-row mt40 center-align">
        <div className="symbol">$</div>
        <div className="input-amount-wp">
          <div className="input-amount-txt">{toCurrency(campaign.budget)}</div>
        </div>
        <div className="symbol">USD</div>
      </div>
      {statusPaymentDone(campaign.status) ?
        <div className="fz14 center-align mt20">Paid</div>
        :
        [
          balancesLoading ?
            <div className="fz14 center-align mt20" key={30}>Loading balance...</div>
            :
            (creditLimit && creditLimit > 0) ?
              (availFunds && availFunds > 0) ? <div className="fz14 center-align mt20" key={31}>Available funds {toCurrency(availFunds, { currency: '$' })}</div> : null
              :
              <div className="fz14 center-align mt20" key={32}>Available funds {toCurrency(availFunds, { currency: '$' })}&nbsp;&nbsp;&nbsp;&nbsp;<a className="c-link" onClick={()=>setFundDialog(true)} href="javascript:void(0)">add funds</a></div>
          ,
          (creditLimit && creditLimit > 0) ?
            <div className="fz14 center-align mt20" key={33}>Available credit {toCurrency(availCredit, { currency: '$' })}</div> : null
        ]
      }
      <ul className="mt40 no-bullet">
        <li>
          <hr className="divider" />
          <div className="payment-summary-row mt15"><span>Subtotal: </span><span>{
            currentCampaignTypeMeta.display_total_value || toCurrency(campaign.budget, { currency: '$' })
          }</span></div>
        </li>
        <li>
          <div className="payment-summary-row mt15"><span>Discount: </span><span>- {currentCampaignTypeMeta.display_discount || '$0.00'}</span></div>
          <hr className="divider mt15" />
        </li>
        <li>
          <div className="payment-summary-row mt15"><span><strong>Total: </strong></span><span>{toCurrency(campaign.budget, { currency: '$' })}</span></div>
        </li>
      </ul>
      {errors && errors.payment &&
        <ErrorBox message={errors.payment.join('; And ')} />
      }
      <div className="justify-row mt40">
        <a className="btn lg outline" href="javascript:void(0)" style={{ visibility: 'hidden' }}>Back</a>&nbsp;
        {statusPaymentDone(campaign.status) ?
          <a className="btn lg fill" href="javascript:void(0)" style={{ visibility: 'hidden' }} onClick={() => setCurrentStep(STEPS.CREATIVE)}>Next</a>
          :
          <a className="btn lg fill" href="javascript:void(0)" style={{ visibility: 'hidden' }} onClick={saveAndPayCampaign}>{saving ? 'Processing...' : 'Pay and Continue'}</a>
        }
      </div>
      <AddFundDialog dialog={fundDialog} setDialog={setFundDialog} advertiser_id={props.advertiser_id} />
    </div>);
  }

  if (!loading || currentStep === STEPS.CREATIVE) {
    stepComponent.push(<div className="constrain-md block-center">
      <h1 id="step-6" className="center-align" style={{borderTop: '1px solid black', paddingTop: '.5em'}}>Creative</h1>
      <div className="mt40">
        <form className="form-stack" aria-label="Creatives">
          {campaign.line_items.map((lineItem) => {
            return <fieldset className="creative-field mt80" id={`line-item-${lineItem.id}`} key={lineItem.id}>
              <div className="creative-header" style={{top: `${secondaryHeight}px`}}>
                <h2 className="h3 fwb">{lineItem.display_line_item_type}</h2>
                <div className="note">Specify your {lineItem.display_line_item_type} contents.</div>
                {lineItem.allow_multiple_creatives &&
                  <button className="spin primary lg js-add" type="button" onClick={() => newCreative(lineItem)} >
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 8.5h8.5m8.5 0H8.5m0 0V0m0 8.5V17" stroke="currentColor"></path>
                    </svg>
                  </button>
                }
              </div>
              {lineItemErrors && lineItemErrors[lineItem.id] && lineItemErrors[lineItem.id].creatives &&
                <ErrorBox message={`${lineItem.display_line_item_type} ${lineItemErrors[lineItem.id].creatives.join('; And ')}.`} />
              }
              <div className="creative-copies">
                {(lineItem.creatives || []).map((creative) => creativeComponent(creative, lineItem))}
              </div>
              {/*<div className="mt30"><a className="c-link link-plain" href="" data-open-dialog="dialog-campaign-preview">Campaign Preview</a></div>*/}
            </fieldset>
          })}
        </form>
      </div>
      {shouldShowCreativeHasErrors && creativeHasErrors &&
        <ErrorBox message="Saved but at least one creative has errors" />
      }
      {campaignReviewSubmissionError &&
        <ErrorBox message="Failed to submit the campaign for review. Please correct the errors first." />
      }
      {/*<div className="justify-row mt40">
        <a className="btn lg outline" href="javascript:void(0)" style={{ visibility: "hidden" }}>Back</a>&nbsp;
        <span>
        <button className="btn lg fill" href="javascript:void(0)" onClick={checkCreatives}>{saving ? 'Processing...' : saved ? 'Saved' : 'Save'}</button>
        <button className="btn lg fill ml10" href="javascript:void(0)" disabled={saving || !canSubmit} onClick={saveAndSubmitCampaign}>{saving ? 'Processing...' : 'Save & submit'}</button>
        </span>
      </div>*/}
    </div>);
  }

  return (
    <div className="container mt40">
      <nav id="campaign-steps" className="campaign-steps" role="navigation" aria-label="Steps" style={{ position: 'sticky', top : 0, padding: '10px', backgroundColor: 'white', zIndex: 9999999 }}>
        <ul className="no-bullet" data-step={currentStep}>
          {STEP_NAV_ITEMS.map((navItem, _) => {
            return <li key={navItem.step} className={STEP_VALIDATORS[navItem.step](campaign, errors) ? '' : 'has-warning'}>
              {/*currentStep <= navItem.step ?
                <span>{navItem.title}</span>
                :
                <a href={`#step-${navItem.step}`}>{navItem.title}</a>
              */}
              <a href={`#step-${navItem.step}`} onClick={() => setCurrentStep(navItem.step)}>{navItem.title}</a>
            </li>
          })}
        </ul>
      </nav>
      {!loading &&
        <div className="constrain-md block-center" style={{ position: 'sticky', top: '64px', zIndex: '9999999' }}>
          <div className="mt40">
            <div className="creative-nav-wp" style={{zIndex: '9999999'}}>
              <nav id="secondary-nav" ref={elementRef} className="creative-nav bg-light round-10 pv10" role="navigation" aria-label="Creatives" style={{width: 'unset', textAlign: 'center', position: 'initial', borderRadius: 'initial', marginTop: '-2.5rem', display: 'block', backgroundColor: '#fff'}}>
                <ul className="no-bullet">
                  {campaign.line_items.map((lineItem) => {
                    return <li className={lineItem.is_valid ? 'active' : null} key={lineItem.id} style={{display: 'inline-block'}}>
                      <a href={`#line-item-${lineItem.id}`}>
                        <span>{lineItem.display_line_item_type}</span> 
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className="circle" d="M25.331 8.364a12 12 0 1 0 3.23 5.422" stroke="#69A417" strokeWidth="2"></path>
                          <path className="line" d="M12 16l5 5L29 9" stroke="#69A417" strokeWidth="2"></path>
                        </svg>
                      </a>
                    </li>
                  })}
                  <li href="javascript:void(0)" onClick={() => saveCampaign()} style={{cursor: 'pointer', borderTop: '1px solid #69a417', display: 'inline-block'}}><span>{saving ? 'Saving...' : 'Save'}</span></li>
                  {saving || !canSubmit ?
                    (<li href="javascript:void(0)" onClick={saveAndSubmitCampaign} style={{cursor: 'pointer', borderTop: '1px solid #69a417', backgroundColor: 'darkgray', display: 'inline-block'}}><span>{saving ? 'Processing...' : 'Save & submit'}</span></li>) : (<li href="javascript:void(0)" onClick={saveAndSubmitCampaign} style={{cursor: 'pointer', borderTop: '1px solid #69a417', display: 'inline-block'}}><span>{saving ? 'Processing...' : 'Save & submit'}</span></li>)
                  }
                </ul>
              </nav>
            </div>
          </div>
        </div>
      }
      {unknownError &&
        <ErrorBox message={'Oops! Something went wrong. Please try again later.'} />
      }
      { currentStep == 6 && campaign.preapproved &&
        <PreApprovedBox message={'This campaign is eligible to be preapproved (skip the review) as long as no changes are made to the creatives'} />
      }
      {!loading &&
        stepComponent
      }
      <CampaignInlineSubmissionConfirmedDialog show={showCampaignInlineSubmissionConfirmedDialog} onCloseClick={handleReviewSubmissionConfirmationClosed}  preApproved={campaign.preapproved ? true : false} />
    </div>
  );
};

CampaignInlineEditForm.propTypes = {
  advertiser_id: PropTypes.number,
  campaign_id: PropTypes.number,
  force_init_step: PropTypes.bool,
};

export default CampaignInlineEditForm;
