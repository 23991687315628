export function toCurrency(number, {currency}={}) {
  const str = number?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  if (!str) { return; }
  currency = currency || '';
  return `${currency}${str}`
};

export const PAYMENT_STEPS = {
  CLOSED: 0,
  CHOOSE_PAYMENT_METHOD: 1,
  ADD_FUND: 2,
  CHOOSE_TYPE: 3,
  ADD_PAYMENT_METHOD: 4
  
};
Object.freeze(PAYMENT_STEPS);

export const ADD_PAYMENT_METHOD_STEPS = {
  INIT: -1,
  CLOSED: 0,
  CHOOSE_TYPE: 1,
  ADD_PAYMENT_METHOD: 2
};
Object.freeze(ADD_PAYMENT_METHOD_STEPS);


export const UPDATE_CARD_STEPS = {
  INIT: -1,
  CLOSED: 0,
  UPDATE_CARD: 1
};
Object.freeze(UPDATE_CARD_STEPS);