// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import ReactOnRails from 'react-on-rails';
import CampaignForm from './components/CampaignForm';
import CampaignInlineEditForm from './components/CampaignInlineEditForm';
import BalanceWidget from './components/BalanceWidget';
import AddPaymentMethodButton from './components/AddPaymentMethodButton';
import UpdateCardButton from './components/UpdateCardButton';
import RemovePaymentMethodButtonDialog from './components/RemovePaymentMethodButtonDialog';
import DeactivateUserButtonDialog from './components/DeactivateUserButtonDialog';

Rails.start()
ActiveStorage.start()

ReactOnRails.register({
  CampaignForm,
  CampaignInlineEditForm,
  BalanceWidget,
  AddPaymentMethodButton,
  UpdateCardButton,
  RemovePaymentMethodButtonDialog,
  DeactivateUserButtonDialog
});


function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}   

docReady(function() {
    let el_edit_avatar = document.querySelector(".input-avatar.js-edit-avatar")
    if (el_edit_avatar) {
        let input = el_edit_avatar.querySelector('[type="file"]:not(.init)');
        if (input) {
            input.classList.add('init');
            input.addEventListener('change', (e) => {
                if (input.files && input.files[0]) {
                    let reader = new FileReader(),
                        img = el_edit_avatar.querySelector('img');
                    if (!img) {
                        let label = el_edit_avatar.querySelector('label'),
                            txt = el_edit_avatar.querySelector('.txt');
                        img = document.createElement('img');
                        label.insertBefore(img, txt);
                    }
                    reader.addEventListener('load', (ele) => {
                        img.src = ele.target.result;
                    });

                    reader.readAsDataURL(input.files[0]);
                }
            });
        }
    }
});


