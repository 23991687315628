import React, { useState, useEffect } from 'react'
import AddFundDialog from './AddFundDialog'
import RedeemPromoCodeDialog from './RedeemPromoCodeDialog'
import { toCurrency } from '../../lib/utils'
import axios from 'axios'

const BalanceWidget = (props) => {
  const {available_funds, current_credit, credit_limit, advertiser_id} = {...props}

  const [availableFunds, setAvailableFunds] = useState(Number(props.available_funds));
  const [currentCredit, setCurrentCredit] = useState(Number(props.current_credit));
  const [creditLimit, setCreditLimit] = useState(Number(props.credit_limit));

  const [fundDialog, setFundDialog] = useState(false)
  const [promoCodeDialog, setPromoCodeDialog] = useState(false)


  useEffect(()=>{
    if (fundDialog === false){
      axios.get(`/advertisers/${advertiser_id}/balance_accounts.json`).then((response)=>{
        setAvailableFunds(response.data.avail_funds);
        if (response.data.credit_used === null || response.data.credit_limit === null) { return; }
        setCurrentCredit(response.data.credit_used);
        setCreditLimit(response.data.credit_limit);     
      }).catch((err)=>{
        console.log(err)
      })
    }
  }, [fundDialog])


  return (
    <div>
      <section className="funds-block has-border round-10 ph20 pv40 center-align">
        <h2 className="h1">
          <span className="sub-title">Available funds</span>{toCurrency(availableFunds, {currency: '$'})}
        </h2>
        <h2 className="h1">
          <span className="sub-title">Current credit / Credit limit </span>{toCurrency(currentCredit, {currency: '$'})} / {toCurrency(creditLimit, {currency: '$'})}
        </h2>
        <div className="buttons center-align mt40">
          <button className ="btn lg fill" onClick={() => setFundDialog(!fundDialog)}>Add funds</button>
          <button className ="btn lg outline" onClick={() => setPromoCodeDialog(!fundDialog)}>Promo code</button>
        </div>
        <div className="note constrain-xs block-center ib mt40">We’ll use your available balance when you make a purchase. <br /> If you don’t have enough funds in your account, we’ll ask you to add funds before you can make a purchase. <br /> Update your <a className="tdu" href={`/advertisers/${advertiser_id}/payment_methods`}>payment methods</a>.
          <br />
          <br /> Need a refund? Please contact our sales team.
        </div>
        <div class="buttons center-align mt40">
          <a class="btn lg outline" href={`/advertisers/${advertiser_id}/cash_refund_requests/new`}>Request Cash Refund</a>
        </div>
      </section>
      <AddFundDialog dialog={fundDialog} setDialog={setFundDialog} advertiser_id={advertiser_id} />
      <RedeemPromoCodeDialog dialog={promoCodeDialog} setDialog={setPromoCodeDialog} advertiser_id={advertiser_id} />
    </div>
  );
};

export default BalanceWidget; 