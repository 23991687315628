import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageIconWarning from '../../../images/icon/icon-warning.svg'

const PreApprovedBox = (props) => {
  return (
    <div className="error icon-warning big" role="alert" aria-relevant="all" style={{ border: "1px solid #ffe037" }} >
      <div className="txt" style={{ margin: "0 auto" }}>{props.message}</div>
    </div>
  );
};

PreApprovedBox.propTypes = {
  message: PropTypes.string,
};

export default PreApprovedBox;
