import React, { useState, useEffect } from 'react'
import { Modal, Dialog } from 'react-dialog-polyfill'
import axios from 'axios'

const RedeemPromoCodeDialog = (props) => {
  const { advertiser_id, dialog, setDialog} = { ...props }

  const [promoCode, setPromoCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleOnClose = () => {
    setDialog(false);
    window.location.reload();
  }

  const handleOnRedeem = (e) =>{
    e.preventDefault()
    axios.post(`/advertisers/${advertiser_id}/promo_codes/${promoCode}/redeem.json`, {
        authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content
    }).then((response)=>{
      handleOnClose()
    }).catch((err)=>{
      console.log(err)
      if (err.response.data.error) {
        setErrorMessage(err.response.data.error)
      } else {
        setErrorMessage(err.message)
      }
    });
  }

  return (
    <div> 
      <Modal className='dialog promo-box fixed center-align' id="dialog-promo-code" open={dialog}>
					<button className="dialog-tool fixed" type="button" aria-label="Close" data-dialog-close="" onClick={() => handleOnClose() }>
					  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					    <path d="M1 13.866l6.433-6.433M13.866 1L7.433 7.433m0 0L1.134 1.134m6.299 6.299L14 14" stroke="currentColor"></path>
					  </svg>
					</button>
			  <div className="mt30">
			    <span className="fz14">Promo Code</span>
			    <input className="input-promo-code input-line ml15" type="text" placeholder="" autoFocus="" required="" 
			    onChange={(e) => setPromoCode(e.target.value)} value={promoCode}/>
			  </div>
			  <div className="mt20">
			    <button className="btn-redeem btn lg fill" href="available-funds.html" onClick={(e)=> handleOnRedeem(e)}>Redeem</button>
			  </div>
			  {errorMessage != '' &&
          <div className="error  small" role="alert" aria-relevant="all">
            <div className="txt">{errorMessage}</div>
          </div>
        }
			</Modal>
    </div>
  );
};

export default RedeemPromoCodeDialog; 