import React from 'react';
import { Modal } from 'react-dialog-polyfill'
import PropTypes from 'prop-types';

const CampaignInlineSubmissionConfirmedDialog = (props) => {
  const { show, onCloseClick = (()=>{}) } = { ...props }


  return <Modal className="dialog fixed center-align" open={show}>
    <h2 className="h3">Thank you for submitting</h2>
    <div className="note mt10">The campaign has been updated and has been set to the previous campaign status.
    </div>
    <button className="btn lg fill mt30" onClick={onCloseClick}>Close</button>
  </Modal>;
};

CampaignInlineSubmissionConfirmedDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

export default CampaignInlineSubmissionConfirmedDialog;
