import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PAYMENT_STEPS } from '../../lib/utils'

const ChoosePaymentMethod = (props) => {
  const {advertiser_id, currentStep, setCurrentStep, currentPaymentMethod, setCurrentPaymentMethod} = { ...props }

	const [paymentMethods, setPaymentMethods] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(true)

  useEffect(()=>{
    if (currentStep === PAYMENT_STEPS.CHOOSE_PAYMENT_METHOD){
      axios.get(`/advertisers/${advertiser_id}/stripe_payment_methods.json`).then((response)=>{
        if (response.data.length === 0) {
          setCurrentStep(PAYMENT_STEPS.CHOOSE_TYPE)
        } else {
          setPaymentMethods(response.data)
          let paymentMethod = response.data[0]
          let matchedPaymentMethods = response.data.filter(p => p.default === true);
          if (matchedPaymentMethods.length > 0){
						paymentMethod = matchedPaymentMethods[0]
          }
	  			setCurrentPaymentMethod({
	  				id: paymentMethod.id,
	  				type: paymentMethod.type,
	  				brand: paymentMethod.brand,
	  				funding: paymentMethod.funding,
	  				last4: paymentMethod.last4
	  			})
          setCurrentStep(PAYMENT_STEPS.CHOOSE_PAYMENT_METHOD)
        }       
      }).catch((err)=>{
      	console.log(err)
	      if (err.response.data.error) {
	        setErrorMessage(err.response.data.error)
	      } else {
	        setErrorMessage(err.message)
	      }
				setCurrentStep(PAYMENT_STEPS.CHOOSE_PAYMENT_METHOD)
      }).finally(()=>{
				setLoading(false)
      })
    }
  }, [currentStep])

  const handleOnLinkCard = () => {
		setCurrentStep(PAYMENT_STEPS.CHOOSE_TYPE)
  }

  const handleOnChooseOne = (value) => {
  	for(let i=0; i<paymentMethods.length; i++){
  		let paymentMethod = paymentMethods[i];
  		if (value === paymentMethod.id){
  			setCurrentPaymentMethod({
  				id: paymentMethod.id,
  				brand: paymentMethod.brand,
  				funding: paymentMethod.funding,
  				last4: paymentMethod.last4
  			})
  		};
  	}
  }

  const handleOnContinue = () => {
  	setCurrentStep(PAYMENT_STEPS.ADD_FUND)
  }

	return (
		<div>
			{loading === true ? (
				<p>Loading...</p>
			):(
				<div>
					{paymentMethods.length > 0 &&
						<div>
						  <h1 className="h3 center-align">Choose a payment method</h1>
						  <ul className="mt30 no-bullet">
						  	{paymentMethods.map((paymentMethod,i)=>(
							    <li key={i}>
							      <div className="radio">
							        <input type="radio" name="card" id={paymentMethod.id} value={paymentMethod.id} 
							        	onChange={(e)=> {handleOnChooseOne(e.target.value)}}
							        	disabled={paymentMethod.type === 'pending_bank_account'} 
							        	checked={paymentMethod.id === currentPaymentMethod.id}
							        	/>
							        <label htmlFor={paymentMethod.id} className="card-box round-10 pd20" >
							          <span className="icon"></span>
							          <span className="txt dib vam">
							            <strong className="name">{paymentMethod.brand}</strong>
							            <span className="info-items mt5">
							            	{paymentMethod.type === 'pending_bank_account' ? (
							            			<a className="fz13 c-danger link-plain" href={paymentMethod.verification_url}>Verify your bank</a>
							            		):(
							            			<span className="note">{paymentMethod.funding}</span>
							            		)
							            	}							              
							              {paymentMethod.type === 'card' &&
							              	<span className="note last-digitals">{paymentMethod.last4}</span>
							              }							         
							            </span>
							          </span>
							        </label>
							      </div>
							    </li>
						  ))}
						  </ul>
						  <div className="center-align mt30">
						    <button className="btn lg fill" onClick={()=> handleOnContinue()}>Continue</button>
						  </div>
						  <div className="mt15 center-align"><button className="btn lg outline" onClick={()=> handleOnLinkCard()}>Link a card/bank account</button></div>						
						</div>
					}
				  {errorMessage != '' &&
            <div className="error  small" role="alert" aria-relevant="all">
              <div className="txt">{errorMessage}</div>
            </div>
          }
          <div className="note constrain-xs block-center ib mt40">We’ll use your available balance when you make a purchase. <br /> If you don’t have enough funds in your account, we’ll ask you to add funds before you can make a purchase. <br /> Update your <a className="tdu" href={`/advertisers/${advertiser_id}/payment_methods`}>payment methods</a>.
        	</div>
				</div>			
			)}
		</div>
	)
}

export default ChoosePaymentMethod