import React, { useState, useEffect } from 'react'
import UpdateCardDialog from './UpdateCardDialog'

const UpdateCardButton = (props) => {
  const {advertiser_id, payment_method} = {...props}

  const [dialog, setDialog] = useState(false)

  useEffect(()=>{
    if (dialog === false){
      // window.location.reload();
    }
  }, [dialog])

  return (
    <div>
      <button className ="btn lg fill" onClick={() => setDialog(!dialog)}>Update card</button>
      <UpdateCardDialog dialog={dialog} setDialog={setDialog} advertiser_id={advertiser_id} payment_method={payment_method}/>
    </div>
  );
};

export default UpdateCardButton; 