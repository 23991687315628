import React from 'react';
import { Modal } from 'react-dialog-polyfill'
import PropTypes from 'prop-types';

const CampaignReviewSubmissionConfirmedDialog = (props) => {
  const { show, onCloseClick = (()=>{}) } = { ...props }


  return <Modal className="dialog fixed center-align" open={show}>
    <h2 className="h3">Thank you for submitting</h2>
    {props.preApproved ? (
      <div className="note mt10">The campaign has been preapproved. <br />You will receive a notification when your campaign is about to run.</div>
      ) : (
      <div className="note mt10">The campaign is under review. This process takes 2-3 business days. <br />You will receive a notification after the review is completed.</div>
    )}
    <button className="btn lg fill mt30" onClick={onCloseClick}>Close</button>
  </Modal>;
};

CampaignReviewSubmissionConfirmedDialog.propTypes = {
  show: PropTypes.bool,
  preApproved: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

export default CampaignReviewSubmissionConfirmedDialog;
